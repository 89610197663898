import React from "react";
export const Savings = ({ savingData }) => {
  return (
    <div className="col-sm-6 col-xl-3">
      <div className="card">
        <div className="card-body">
          <div className="d-flex align-items-start justify-content-between">
            <div className="content-left">
              <span>{savingData?.title}</span>
              <div className="d-flex align-items-center my-2">
                <h4 className="mb-0 me-2">
                  {savingData?.savings_count
                    ? `SAR ${savingData?.savings_count?.toLocaleString()}`
                    : "--"}
                </h4>
                {/*<p className="text-success mb-0">(+42%)</p>*/}
              </div>
              <p className="mb-0">{savingData?.sub_title}</p>
            </div>
            <span className="badge bg-label-warning rounded p-2">
              <i className="ti ti-pig ti-sm"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
