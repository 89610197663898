import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-xhr-backend";

import english from "./locales/en/translations.json";
import arabic from "./locales/ar/translations.json";

i18n
  .use(XHR)
  .use(LanguageDetector)
  .init({
    lng:"en",
    fallbackLng: "en", // use en if detected lng is not available
    interpolation: {
      escapeValue: false // react already safes from xss
    },

    resources: {
      en: {
        translations: english
      },
      ar: {
        translations: arabic
      }
    },
    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations"
  });

export default i18n;