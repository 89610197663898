import React from 'react'

import {AppKeysOverview} from './appKeysOverview'
import { CategoriesBreakDown } from './categoriesBreakDown'
import { TopMerchants } from './topMerchants'
import { EstimatedSavings } from './charts/estimatedSavings'
import { RedemptionTracking } from './charts/redemptionTracking'

export const userAnalytics = ({merchantData, categoryData}) => {
  return (
    <>
      <AppKeysOverview/>
      <RedemptionTracking/>
      <EstimatedSavings/>
      <div className="row g-4">
        <CategoriesBreakDown categoryData={categoryData}/>
        <TopMerchants merchantData={merchantData}/>
      </div>
    </>
  )
}