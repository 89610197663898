//Company Code
export const REWARD_COMPANY_CODE = "rwd";
export const NISSAN_COMPANY_CODE = "nsn";
// API Response Code
export const SUCCESS_CODE = 200;
export const INVALID_CODE = 422;
export const EMPLOYEE_ADDED = 1;
export const EMPLOYEE_UPDATED = 2;
export const REQUEST_FAILED = -1;
export const KEY_ADDED = 3;
export const INVALID_CODES = [400, 422];
export const SUCCESS_CODES = [200, 201];

export const apiEndpoints = {
  add_employee: "/employee/v1/registeremployee",
  edit_employee: "/employee/v1/updateemployee",
  reset_password_link: "/employee/v1/resetemployeepassword",
  verify_email: "/employee/v1/verifyemployeeemail"
};

export const languages = [
  { label: "English", value: "English" },
  { label: "Arabic", value: "Arabic" },
];
