import { EmployeeList } from "../Components/Employee/employeeList";
import { Savings } from "../Components/Dashboard/savings";
import { TotalKeys } from "../Components/Employee/totalKeys";
import { UnassignedKeys } from "../Components/Employee/unassignedKeys";
import { ActiveEmployee } from "../Components/Employee/activeEmployee";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ApiWrapper } from "../Api/apiWrapper";

export const Employee = () => {
  const user = useSelector((state) => state.user.user);
  const localData = JSON.parse(localStorage.getItem("data"));

  const [loading, setLoading] = useState(true);
  const [totalPurchasedKeys, setTotalPurchasedKeys] = useState({});
  const [unassignedKeys, setUnassignedKeys] = useState({});
  const [activeEmployee, setActiveEmployee] = useState({});
  const [savingData, setSavingData] = useState({});
  const [employeeData, setEmployeeData] = useState([]);

  useEffect(() => {
    const requestData = JSON.stringify({
      company_code: user.company_code,
      session_token: localData.session_token,
      locale: localStorage.getItem("i18nextLng"),
    });

    const apiEndpoints = [
      "/employee/v1/gettotalpurchasedkeys",
      "/employee/v1/getunassignedkeys",
      "/dashboard/v1/getuserscount",
      "/dashboard/v1/getsavingscount",
      "/dashboard/v1/getallemployees",
    ];

    const apiPromises = apiEndpoints?.map((endpoint) =>
      ApiWrapper({
        path: endpoint,
        method: "POST",
        data: requestData,
      })
    );

    Promise.all(apiPromises)
      .then((data) => {
        setTotalPurchasedKeys(data[0].data);
        setUnassignedKeys(data[1].data);
        setActiveEmployee(data[2].data);
        setSavingData(data[3].data);
        setEmployeeData(data[4].data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  return (
    <>
      {!loading ? (
        <div className="layout-page">
          <div className="content-wrapper">
            <div className="container-fluid flex-grow-1 container-p-y">
              <div className="row g-4 mb-4">
                <TotalKeys totalPurchasedKeys={totalPurchasedKeys} />
                <UnassignedKeys unassignedKeys={unassignedKeys} />
                <ActiveEmployee activeEmployee={activeEmployee} />
                <Savings savingData={savingData} />
              </div>
              <EmployeeList
                requestKeyButton={employeeData?.length}
                employeeData={employeeData}
                setEmployeeData={setEmployeeData}
              />
              {/*<NoEmploy/>*/}
            </div>
          </div>
        </div>
      ) : (
        <div className="pageLoader d-flex align-items-center justify-content-center">
          <div className="spinner-border spinner-border-lg text-primary"></div>
        </div>
      )}
    </>
  );
};
