import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist';
import userReducer from './userSlice';
import moduleReducer from './moduleSlice';
import storage from 'redux-persist/lib/storage';
import configSlice from './configSlice'

const rootReducer = combineReducers({
  user: userReducer,
  module: moduleReducer,
  config: configSlice,
});

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer
});

export const persistor = persistStore(store)

export default store;