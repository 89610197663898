import React from "react";

export const Preferences = ({
  terms,
  languagePreference,
  emailPreference,
  currency,
}) => {
  return (
    <div className="card mb-4">
      <div className="card-body">
        <h5 className="card-title mb-4">Preferences</h5>
        <ul className="list-unstyled">
          <li className="d-flex align-items-start justify-content-between mb-2">
            <span className="fw-semibold w-50">Default Currency</span>
            <span>{currency}</span>
          </li>
          <li className="d-flex align-items-start justify-content-between mb-2">
            <span className="fw-semibold w-50">Language Preference</span>
            <span>{languagePreference}</span>
          </li>
          <li className="d-flex align-items-start justify-content-between mb-2">
            <span className="fw-semibold w-50">Email Preferences</span>
            <span>{emailPreference ? "Yes" : "No"}</span>
          </li>
          <li className="d-flex align-items-start justify-content-between mb-2">
            <span className="fw-semibold w-50">Accepts Terms</span>
            <span>{terms ? "Yes" : "No"}</span>
          </li>
        </ul>
      </div>
    </div>
  );
};
