import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export const VerifyEmail = ({activationLinkMessage, setLinkSend}) => {
  const {t} = useTranslation();
  const onLinkResendHandler = () => {
    setLinkSend(false);
  }
  return (
    <div className="authentication-wrapper authentication-basic px-4">
      <div className="authentication-inner py-4">
        <div className="card">
          <div className="card-body">
            <div className="app-brand justify-content-center mb-4 mt-2">
              <span className="app-brand-link">
                <img src="/images/app-logo-vertical.png" alt="Reward Logo" className="img-fluid"/>
              </span>
            </div>
            <h4 className="mb-1 pt-2">{t("email_verification_screen.verify_your_email")} ✉️</h4>
            <p className="text-start mb-4">
              {activationLinkMessage}

            </p>
            <Link className="btn btn-primary w-100 mb-3" to="">{t("email_verification_screen.open_email")}</Link>
            <p className="text-center mb-0">
              {t("email_verification_screen.email_did_not_get")}
              <button onClick={onLinkResendHandler} className="auth-link">{t("email_verification_screen.resend")}</button>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}