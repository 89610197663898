import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ApiWrapper } from '../../Api/apiWrapper'
import { SUCCESS_CODE } from '../../Api/constants'

export const LoginDevices = () => {

  const user = useSelector((state) => state.user.user);
  const localData = JSON.parse(localStorage.getItem("data"));
  const [loginDevices, setLoginDevices] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const requestData = JSON.stringify({
      company_code: user.company_code,
      session_token: localData.session_token,
      locale: localStorage.getItem("i18nextLng")
    });

    (async () => {
      setLoading(true);
      const data = await ApiWrapper({
        path:"/auth/v1/recentdevices",
        method:"POST",
        data:requestData
      });
      if(data.status_code === SUCCESS_CODE) {
        setLoginDevices(data.data);
        setLoading(false);
      }
    })();
  }, []);

  return(
    <div className="card mb-4">
      <h5 className="card-header">Recent Devices</h5>
      <div className="table-responsive">
        <table className="table border-top">
          <thead>
          <tr>
            <th className="text-truncate">Browser</th>
            <th className="text-truncate">Device</th>
            <th className="text-truncate">Location</th>
            <th className="text-truncate">Recent Activities</th>
          </tr>
          </thead>
          <tbody>
          {!loading && loginDevices?.map((loginDevice, index) => (
            <tr key={index}>
              <td className="text-truncate">
                <i className="ti ti-brand-windows text-info ti-xs me-2"></i>
                <span className="fw-medium">{loginDevice.browser_name}</span>
              </td>
              <td className="text-truncate">{loginDevice.device_name}</td>
              <td className="text-truncate">{loginDevice.country_name}</td>
              <td className="text-truncate">{loginDevice.activity_time}</td>
            </tr>
          ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}