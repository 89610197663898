import React, { useEffect, useState } from "react";
import { EmployeeInfo } from "../Components/Employee/employeeProfile/employeeInfo";
import { LoginInfo } from "../Components/Employee/employeeProfile/loginInfo";
import { MembershipDetail } from "../Components/Employee/employeeProfile/membershipDetail";
import { Preferences } from "../Components/Employee/employeeProfile/preferences";
import { useSelector } from "react-redux";
import { ApiWrapper } from "../Api/apiWrapper";
import { SUCCESS_CODES } from "../Api/constants";
import { useParams } from "react-router-dom";

export const EmployeeProfile = () => {
  const { id } = useParams();
  const user = useSelector((state) => state.user.user);
  const localData = JSON.parse(localStorage.getItem("data"));
  const [employeeDetail, setEmployeeDetail] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getSelectedEmployee();
  }, []);

  const getSelectedEmployee = async () => {
    const requestData = JSON.stringify({
      company_code: user.company_code,
      session_token: localData.session_token,
      locale: localStorage.getItem("i18nextLng"),
      employee_id: id,
    });
    const data = await ApiWrapper({
      path: "/employee/v1/getemployeedetails",
      method: "POST",
      data: requestData,
    });
    if (SUCCESS_CODES.includes(data.status_code)) {
      setEmployeeDetail(data.data);
      setLoading(false);
    }
  };
  return (
    <>
      {loading ? (
        <div className="pageLoader d-flex align-items-center justify-content-center">
          <div className="spinner-border spinner-border-lg text-primary"></div>
        </div>
      ) : (
        <div className="layout-page">
          <div className="content-wrapper">
            {/*<EditProfile show = {show} handleShow = {handleShow} userData={userData} setUserData={setUserData}/>*/}
            <div className="container-fluid container-p-y">
              <div className="row g-4 position-relative">
                <EmployeeInfo
                  employeeDetail={employeeDetail}
                  getSelectedEmployee={getSelectedEmployee}
                />
                <div className="col-lg-8 col-md-8 order-0 order-md-1">
                  <LoginInfo
                    isEmailVerified={employeeDetail.is_email_verified}
                    employee_id={employeeDetail.id}
                  />
                  <MembershipDetail employeeDetail={employeeDetail} />
                  <Preferences
                    terms={employeeDetail.accepted_terms}
                    currency={employeeDetail.currency}
                    languagePreference={employeeDetail.language_preference}
                    emailPreference={employeeDetail.receive_email}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
