import React, { useState } from "react";
// import { AddEmployee } from "../Employee/addEmployee";
import { useSelector } from "react-redux";
import { ApiWrapper } from "../../Api/apiWrapper";
import { SUCCESS_CODE } from "../../Api/constants";
import { EditEmployee } from "../Employee/employeeProfile/editEmployee";

const NoEmploy = ({ setEmployeeData }) => {
  const user = useSelector((state) => state.user.user);
  const [addEmployeeComponent, setAddEmployeeComponent] = useState(false);
  const toggleAddEmployeeComponent = () => {
    setAddEmployeeComponent(!addEmployeeComponent);
  };
  const fetchEmployeeList = async (status = "", tier = null) => {
    try {
      const localData = JSON.parse(localStorage.getItem("data"));
      const requestData = JSON.stringify({
        company_code: user.company_code,
        session_token: localData.session_token,
        status: status == -1 ? "" : status,
        user_group: tier == -1 ? null : tier,
        locale: localStorage.getItem("i18nextLng"),
      });
      const data = await ApiWrapper({
        path: "/dashboard/v1/getallemployees",
        method: "POST",
        data: requestData,
      });
      if (data.status_code === SUCCESS_CODE) {
        const jsonData = JSON.stringify(data.data);
        const parsedData = JSON.parse(jsonData);
        setEmployeeData(parsedData);
      } else {
        console.log("something went wrong");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="row" style={{ height: "calc(100% - 170px)" }}>
      <div className="col-12">
        <div className="card h-100 align-items-center justify-content-center">
          <div className="content_noEmployee">
            <div className="icon d-flex align-items-center justify-content-center mx-auto mb-4">
              <i className="menu-icon tf-icons ti ti-xl ti-users text-primary m-0"></i>
            </div>
            <h3 className="card-title mb-3">There is no employee</h3>
            <p className="text-muted mb-4">
              Get started by adding employees. all of your
              <br /> employees will be listed in employee tab
            </p>
            <div className="row">
              <div className="col-md-12 col-12">
                <button
                  type="button"
                  className="btn btn-outline-primary waves-effect w-100"
                  onClick={toggleAddEmployeeComponent}
                >
                  Add Employee
                </button>
                {/* <AddEmployee
                  show={addEmployeeComponent}
                  toggleAddEmployeeComponent={toggleAddEmployeeComponent}
                  fetchEmployeeList={fetchEmployeeList}
                /> */}
                {addEmployeeComponent && (
                  <EditEmployee
                    title="Add Employee"
                    oKBtnText="Save"
                    onClose={toggleAddEmployeeComponent}
                    callback={fetchEmployeeList}
                    endpoint={"add_employee"}
                  />
                )}
              </div>
              {/*<div className='col-md-6 col-12'>*/}
              {/*  <button type="button" className="btn btn-outline-primary waves-effect w-100">Bulk Upload</button>*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoEmploy;
