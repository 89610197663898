import React from "react";
export const Offers = ({ offersData }) => {
  return (
    <div className="col-sm-6 col-xl-3">
      <div className="card">
        <div className="card-body">
          <div className="d-flex align-items-start justify-content-between">
            <div className="content-left">
              <span>{offersData?.title}</span>
              <div className="d-flex align-items-center my-2">
                <h4 className="mb-0 me-2">
                  {offersData?.offers_count?.toLocaleString() || "--"}
                </h4>
              </div>
              <p className="mb-0">{offersData?.sub_title}</p>
            </div>
            <span className="badge bg-label-danger rounded p-2">
              <i className="ti ti-tag ti-sm"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
