import React, { useEffect, useState } from "react";
import Flatpickr from "react-flatpickr";

import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { NISSAN_COMPANY_CODE, SUCCESS_CODE } from "../../Api/constants";
import { ApiWrapper } from "../../Api/apiWrapper";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const data = [
  {
    name: "01/01",
    active: 4000,
    deactivated: 2400,
    unassigned: 2400,
    inactive: 2400,
  },
  {
    name: "02/01",
    active: 3000,
    deactivated: 1398,
    unassigned: 2210,
    inactive: 2210,
  },
  {
    name: "03/01",
    active: 2000,
    deactivated: 9800,
    unassigned: 2290,
    inactive: 2000,
  },
  {
    name: "04/01",
    active: 2780,
    deactivated: 3908,
    unassigned: 2000,
    inactive: 2000,
  },
  {
    name: "05/01",
    active: 1890,
    deactivated: 4800,
    unassigned: 2181,
    inactive: 1890,
  },
  {
    name: "06/01",
    active: 2390,
    deactivated: 3800,
    unassigned: 2500,
    inactive: 3800,
  },
  {
    name: "07/01",
    active: 4000,
    deactivated: 2400,
    unassigned: 2400,
    inactive: 2400,
  },
  {
    name: "08/01",
    active: 3000,
    deactivated: 1398,
    unassigned: 2210,
    inactive: 2210,
  },
  {
    name: "09/01",
    active: 2000,
    deactivated: 9800,
    unassigned: 2290,
    inactive: 2000,
  },
  {
    name: "10/01",
    active: 2780,
    deactivated: 3908,
    unassigned: 2000,
    inactive: 2000,
  },
  {
    name: "11/01",
    active: 1890,
    deactivated: 4800,
    unassigned: 2181,
    inactive: 1890,
  },
  {
    name: "12/01",
    active: 2390,
    deactivated: 3800,
    unassigned: 2500,
    inactive: 3800,
  },
];

export const AppKeysOverview = () => {
  const { t } = useTranslation();

  const [appKeysOverviewData, setAppKeysOverviewData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeCount, setActiveCount] = useState(0);
  const [inactiveCount, setInactiveCount] = useState(0);
  const [deactivatedCount, setDeactivatedCount] = useState(0);
  const [unassignedCount, setUnassignedCount] = useState(0);
  const user = useSelector((state) => state.user.user);

  const today = new Date();
  const previousDate = new Date(today);
  previousDate.setDate(today.getDate() - 20);
  const [startDate, setStartDate] = useState(previousDate);
  const [endDate, setEndDate] = useState(today);
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if ((start && end) !== undefined) {
      fetchAppkeysAnalytics(start, end);
    }
  };

  const fetchAppkeysAnalytics = async (sDate, eDate) => {
    const localData = JSON.parse(localStorage.getItem("data"));
    const requestData = JSON.stringify({
      company_code: user.company_code,
      session_token: localData.session_token,
      locale: localStorage.getItem("i18nextLng"),
      start_date:
        sDate.getFullYear() +
        "-" +
        (sDate.getMonth() + 1) +
        "-" +
        sDate.getDate(),
      end_date:
        eDate.getFullYear() +
        "-" +
        (eDate.getMonth() + 1) +
        "-" +
        eDate.getDate(),
    });
    const data = await ApiWrapper({
      path: "/dashboard/v1/getappkeysanalytics",
      method: "POST",
      data: requestData,
    });
    if (data.status_code === SUCCESS_CODE) {
      const jsonData = JSON.stringify(data.data);
      const parsedData = JSON.parse(jsonData);

      let updatedObject = [];
      let activeKeyCount = 0;
      let inactiveKeyCount = 0;
      let deActivateKeyCount = 0;
      let unassignedKeyCount = 0;
      Object.keys(parsedData.analytics).forEach((oldKey) => {
        activeKeyCount = activeKeyCount + parsedData.analytics[oldKey].active;
        inactiveKeyCount =
          inactiveKeyCount + parsedData.analytics[oldKey].inactive;
        deActivateKeyCount =
          deActivateKeyCount + parsedData.analytics[oldKey].deactivated;
        unassignedKeyCount =
          unassignedKeyCount + parsedData.analytics[oldKey].unassigned;

        setActiveCount(activeKeyCount);
        setInactiveCount(inactiveKeyCount);
        setDeactivatedCount(deActivateKeyCount);
        setUnassignedCount(unassignedKeyCount);

        // Split the original date string by "-"
        const parts = oldKey.split("-");
        // Extract the month and day
        const month = parts[1];
        const day = parts[2];
        // Get the new key from the mapping
        const newKey = `${day}/${month}`;
        // Copy the value from the original object to the updated object
        updatedObject[newKey] = parsedData.analytics[oldKey];
        updatedObject[newKey]["name"] = newKey;
        updatedObject = Object.values(updatedObject);
      });
      setAppKeysOverviewData(updatedObject);
      setLoading(false);
    } else {
      console.log("something went wrong");
    }
  };

  useEffect(() => {
    fetchAppkeysAnalytics(startDate, endDate);
  }, []);

  return (
    <div className="row g-4 mb-4">
      <div className="col-12">
        <div className="card">
          <div className="card-header header-elements mb-3">
            <h4 className="card-title mb-0">{t("app_keys.app_keys_title")}</h4>
            <div className="card-action-element d-flex ms-auto py-0">
              <div className="selectHolder ms-5">
                {/*<select className="form-select" style={ { minWidth: '124px' } }>*/}
                {/*  <option>Junior Level</option>*/}
                {/*  <option>Mid Level</option>*/}
                {/*  <option>Senior Level</option>*/}
                {/*</select>*/}
              </div>
              <div className="savingHead d-flex align-items-center">
                <span className="badge bg-label-lighGreen text-primary rounded p-2"></span>
                <h4 className="mb-0 ms-3">
                  {t("app_keys.total_keys")}:{" "}
                  {activeCount +
                    inactiveCount +
                    unassignedCount +
                    deactivatedCount}
                </h4>
              </div>
              <div className="datePicker ms-3">
                <span className="icon">
                  <i className="ti ti-calendar-event ti-xs"></i>
                </span>
                <Flatpickr
                  style={{ width: "251px" }}
                  className="form-control"
                  options={{
                    mode: "range", // Set the mode to 'range' to enable range selection
                    enableTime: false, // Enable time selection
                    dateFormat: "m/d/Y",
                    defaultDate: [startDate, endDate],
                  }}
                  selected={startDate}
                  onChange={onChange}
                />
              </div>
            </div>
          </div>
          {!loading && (
            <>
              <div className="keysOverviewChart">
                <ResponsiveContainer
                  width={"100%"}
                  height={385}
                  min-width={300}
                >
                  <BarChart
                    barGap={0}
                    width={500}
                    height={300}
                    data={appKeysOverviewData}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Bar
                      dataKey="active"
                      fill="#80C100"
                      radius={[10, 10, 0, 0]}
                    />
                    <Bar
                      dataKey="deactivated"
                      fill="#D8D8D8"
                      radius={[10, 10, 0, 0]}
                    />
                    <Bar
                      dataKey="unassigned"
                      fill="#FFC58E"
                      radius={[10, 10, 0, 0]}
                    />
                    <Bar
                      dataKey="inactive"
                      fill="#AFAFAF"
                      radius={[10, 10, 0, 0]}
                    />
                  </BarChart>
                </ResponsiveContainer>
              </div>
              <div className="keysChart_labels card-header d-flex justify-content-center">
                <span className="activeKeys label">
                  {t("app_keys.label_active")}{" "}
                  <span className="fw-bold num">{activeCount}</span>
                </span>
                <span className="deActiveKeys label">
                  {t("app_keys.label_deactivated")}{" "}
                  <span className="fw-bold num">{deactivatedCount}</span>
                </span>
                <span className="unAssignedKeys label">
                  {t("app_keys.label_keyunassigned")}{" "}
                  <span className="fw-bold num">{unassignedCount}</span>
                </span>
                <span className="inctiveKeys label">
                  {t("app_keys.label_inactive")}{" "}
                  <span className="fw-bold num">{inactiveCount}</span>
                </span>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
