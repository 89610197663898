import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ApiWrapper } from "../../Api/apiWrapper";
import { INVALID_CODE, SUCCESS_CODE } from "../../Api/constants";
import { PasswordReset } from "./passwordReset";

export const SetPassword = () => {
  const { t } = useTranslation();
  let errors = {};
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [passwordResetMessage, setPasswordResetMessage] = useState("");
  const [isPasswordReset, setIsPasswordReset] = useState(false);
  const [loading, setLoading] = useState(false);

  const passwordChangeHandler = (event) => {
    setPassword(event.target.value);
  };

  const confirmPasswordChangeHandler = (event) => {
    setConfirmPassword(event.target.value);
  };
  const validateSetPasswordForm = () => {
    // Password validation
    if (!password) {
      errors.password = "Password field is required";
    }
    if (!confirmPassword) {
      errors.confirmPassword = "Confirm Password field is required";
    }
    // password match
    if (password !== confirmPassword) {
      errors.confirmPassword = "Password does not match";
    }

    // Set formErrors state
    setFormErrors(errors);
    // Return true if there are no errors
    return Object.keys(errors)?.length === 0;
  };
  const onSetPasswordHandler = (event) => {
    event.preventDefault();
    if (validateSetPasswordForm()) {
      setLoading(true);
      const urlParams = new URLSearchParams(window.location.search);
      const requestData = JSON.stringify({
        password: password,
        confirm_password: confirmPassword,
        password_reset_token: urlParams.get("token"),
        locale: localStorage.getItem("i18nextLng"),
      });
      (async () => {
        const data = await ApiWrapper({
          path: "/auth/v1/setpassword",
          method: "POST",
          data: requestData,
        });
        if (data.status_code === SUCCESS_CODE) {
          setPasswordResetMessage(data.message);
          setIsPasswordReset(true);
          setLoading(false);
        } else {
          setLoading(false);
          errors.password = data.message;
        }
      })();
    }
  };
  return (
    <>
      {!isPasswordReset ? (
        <div className="container-xxl">
          <div className="authentication-wrapper authentication-basic container-p-y">
            <div className="authentication-inner py-4">
              <div className="card">
                <div className="card-body">
                  <div className="app-brand justify-content-center mb-4 mt-2">
                    <span className="app-brand-link">
                      <img
                        src="/images/app-logo-vertical.png"
                        alt="Reward Logo"
                        className="img-fluid"
                      />
                    </span>
                  </div>
                  <h4 className="mb-1 pt-2">
                    {t("set_password_Screen.set_new_password")}
                  </h4>
                  <p className="mb-4">{t("set_password_Screen.instruction")}</p>
                  <form onSubmit={onSetPasswordHandler}>
                    <div className="mb-3 form-password-toggle">
                      <label className="form-label" htmlFor="password">
                        {t("set_password_Screen.form_fields.new_password")}
                      </label>
                      <div className="input-group input-group-merge">
                        <input
                          type="password"
                          id="password"
                          className="form-control"
                          name="password"
                          placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                          aria-describedby="password"
                          value={password}
                          onChange={passwordChangeHandler}
                        />
                        {/*<span className="input-group-text cursor-pointer"><i className="ti ti-eye-off"></i></span>*/}
                      </div>
                      {formErrors.password && (
                        <span className="error-color">
                          {formErrors.password}
                        </span>
                      )}
                    </div>
                    <div className="mb-3 form-password-toggle">
                      <label className="form-label" htmlFor="confirm-password">
                        {t("set_password_Screen.form_fields.confirm_password")}
                      </label>
                      <div className="input-group input-group-merge">
                        <input
                          type="password"
                          id="confirm-password"
                          className="form-control"
                          name="confirm-password"
                          placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                          aria-describedby="password"
                          value={confirmPassword}
                          onChange={confirmPasswordChangeHandler}
                        />
                        {/*<span className="input-group-text cursor-pointer"><i className="ti ti-eye-off"></i></span>*/}
                      </div>
                      {formErrors.confirmPassword && (
                        <span className="error-color">
                          {formErrors.confirmPassword}
                        </span>
                      )}
                    </div>
                    <button className="btn btn-primary d-grid w-100 mb-3">
                      {t("set_password_Screen.form_fields.set_new_password")}
                    </button>
                    <div className="text-center">
                      <Link to="/login" className="auth-link">
                        <i className="ti ti-chevron-left scaleX-n1-rtl"></i>
                        {t("buttons.back_to_sign_in")}
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <PasswordReset passwordResetMessage={passwordResetMessage} />
      )}

      {loading ? (
        <div className="pageLoader d-flex align-items-center justify-content-center">
          <div className="spinner-border spinner-border-lg text-primary"></div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
