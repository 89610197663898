import React, { useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import Select from "react-select";

export const CategoryList = ({ categoryData = [] }) => {
  const [selectedStatus, setSelectedStatus] = useState();

  const columns = [
    {
      name: "CATEGORY NAME",
      selector: (row) => `${row.name}`,
      cell: (row) => (
        <div className="d-flex align-items-center">
          <div
            className="userImg d-flex align-items-center justify-content-center me-2"
            style={{
              width: "38px",
              height: "38px",
              overflow: "hidden",
              background: "rgba(115,103,240,0.16)",
              color: "#1EB0B3",
            }}
          >
            {row.image ? (
              <img
                src={row.image}
                alt=""
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            ) : (
              <p className="m-0">{row.name?.charAt(0).toUpperCase()}</p>
            )}
          </div>
          <div className="title">{row?.name}</div>
        </div>
      ),
      sortable: true,
      minWidth: "35%",
    },
    {
      name: "NO OF MERCHANTS",
      selector: (row) => row?.merchants_count,
      sortable: true,
    },
    {
      name: "STATUS",
      cell: (row) => {
        const getStatusClassName = (isActive) => {
          if (isActive) {
            return "badge bg-label-success mt-1";
          } else {
            return "badge bg-label-secondary mt-1";
          }
        };
        const className = getStatusClassName(row.is_active);
        return (
          <span className={className}>
            {row.is_active ? "Active" : "Locked"}
          </span>
        );
      },
      sortable: true,
    },
    // {
    //   name: "ACTION",
    //   cell: (row) => {
    //     const getStatusClassName = (isActive) => {
    //       if (isActive) {
    //         return "btn btn-outline-warning waves-effect mt-1";
    //       } else {
    //         return "btn btn-outline-primary waves-effect mt-1";
    //       }
    //     };
    //     const className = getStatusClassName(row.is_active);
    //     return (
    //       <span className={className}>
    //         {row.is_active ? "Requested" : "Send Request"}
    //       </span>
    //     );
    //   },
    //   sortable: true,
    // },
  ];

  const filteredData = useMemo(() => {
    return categoryData?.filter((item) =>
      selectedStatus
        ? (selectedStatus === "Active" && item.is_active) ||
          (selectedStatus === "Locked" && !item.is_active)
        : true
    );
  }, [categoryData, selectedStatus]);

  const handleStatusChange = (value) => {
    setSelectedStatus(value);
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: "1px solid #dbdade", // Border color
      borderRadius: "6px", // Border radius
      cursor: "pointer",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#80C100" : "white", // Background color on hover/focus
      color: state.isFocused ? "white" : "black", // Text color on hover/focus
      boxShadow: state.isFocused ? "none" : "none", // Add box shadow on focus
      borderRadius: "6px",
    }),
    menu: (provided) => ({
      ...provided,
      boxShadow: "0 0 0 1px rgba(0,0,0,0.1)", // Add box shadow on focus
      border: "0", // Border color for the menu
      borderRadius: "6px", // Border radius for the menu
      padding: "10px",
    }),
  };

  const statusOptions = [
    { label: "Active", value: "Active" },
    { label: "Locked", value: "Locked" },
  ];

  return (
    <>
      <div className="row g-4 mb-4">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <div className="dataTable-head">
                <div className="row g-4 align-items-center ">
                  <div className="col-6">
                    <h5 className="card-title mb-0">Categories</h5>
                  </div>
                  <div className="col-6 d-flex justify-content-end ">
                    <div className="card-action-element">
                      {
                        <>
                          <div
                            className="selectHolder"
                            style={{ width: "185px" }}
                          >
                            <Select
                              styles={customStyles}
                              menuPlacement="top"
                              placeholder={"Select Status"}
                              isClearable
                              options={statusOptions}
                              onChange={(val) => {
                                handleStatusChange(val?.label);
                              }}
                            />
                          </div>
                        </>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tableHolder">
              <DataTable
                className="tableHolderInner"
                columns={columns}
                data={filteredData}
                pagination={true}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
