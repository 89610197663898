import React, { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { LoginDevices } from "../Components/profile/loginDevices";
import { ChangePassword } from "../Components/profile/changePassword";
import { UserInfo } from "../Components/profile/userInfo";
import { Notification } from "../Components/profile/notification";
import { EditProfile } from "../Components/profile/editProfile";
import { SUCCESS_CODE } from "../Api/constants";
import { ApiWrapper } from "../Api/apiWrapper";
import { useSelector } from "react-redux";

export const Profile = () => {
  const user = useSelector((state) => state.user.user);
  const localData = JSON.parse(localStorage.getItem("data"));
  const [userData, setUserData] = useState();
  const [loading, setLoading] = useState(true);
  const [showEditModal, setShowEditModal] = useState(false);

  useEffect(() => {
    const requestData = JSON.stringify({
      company_code: user.company_code,
      session_token: localData.session_token,
      user_id: user.id,
      locale: localStorage.getItem("i18nextLng"),
    });

    (async () => {
      const data = await ApiWrapper({
        path: "/auth/v1/userdetails",
        method: "POST",
        data: requestData,
      });
      if (data.status_code === SUCCESS_CODE) {
        setUserData(data.data);
        setLoading(false);
      }
    })();
  }, [loading]);

  return (
    <>
      {loading ? (
        <div className="pageLoader d-flex align-items-center justify-content-center">
          <div className="spinner-border spinner-border-lg text-primary"></div>
        </div>
      ) : (
        <div className="layout-page">
          <div className="content-wrapper">
            {showEditModal && (
              <EditProfile
                onClose={() => setShowEditModal(false)}
                userData={userData}
                setUserData={setUserData}
              />
            )}
            <div className="container-fluid container-p-y">
              <div className="row g-4">
                <UserInfo
                  handleShow={() => setShowEditModal(true)}
                  userData={userData}
                />
                <div className="col-lg-8 col-md-8 order-0 order-md-1">
                  <Tabs
                    defaultActiveKey="security"
                    id="uncontrolled-tab-example"
                    className="nav nav-pills tabs bg-transparent border-0 flex-column flex-md-row mb-4"
                  >
                    <Tab
                      className="nav-item"
                      eventKey="security"
                      title={
                        <span>
                          <i className="ti ti-lock ti-xs me-1"></i>Security
                        </span>
                      }
                    >
                      <LoginDevices />
                      <ChangePassword />
                    </Tab>
                    {/* <Tab
                      className="nav-item"
                      eventKey="notifications"
                      title={
                        <span>
                          <i className="ti ti-bell ti-xs me-1"></i>{" "}
                          Notifications
                        </span>
                      }
                    >
                      <Notification />
                    </Tab> */}
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
