import Offcanvas from "react-bootstrap/Offcanvas";
import React from "react";

export const ResponseModal = ({
  toggleMainComponent,
  operation,
  heading,
  message,
}) => {
  const handleCancel = (e) => {
    e.preventDefault();
    toggleMainComponent();
  };
  return (
    <div className="d-flex flex-column h-100 reqSuccess text-center">
      <Offcanvas.Header closeButton className="mb-5">
        <Offcanvas.Title>&nbsp;</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="pt-5">
        <div className="img mb-5 mx-auto" style={{ maxWidth: "152px" }}>
          <img
            src={
              operation == -1 ? "/images/error.png" : "/images/success-img.png"
            }
            alt="success image"
            className="img-fluid"
          />
        </div>
        <h3 className="mb-3">{heading}</h3>
        <p className="mb-4">{message}</p>
        {/*Operation 1 mean employee added*/}
        {/* {operation === 1 && (
          <button className="btn btn-outline-primary w-75">Request Keys</button>
        )} */}
        <div className="bottom-btnWrap position-absolute bottom-0 start-0 end-0 pb-4 ps-4 pe-4">
          <button
            className="btn btn-primary d-grid w-100"
            onClick={handleCancel}
          >
            OK
          </button>
        </div>
      </Offcanvas.Body>
    </div>
  );
};
