import DataTable from "react-data-table-component";
import { EmptyListing } from "../../Shared/EmptyListing";

export const OfferListing = ({ offerData = [] }) => {
  const getProgressBarClasses = (percentage) => {
    let classes = "bg-primary";
    if (percentage < 26) {
      classes = "bd-danger";
    } else if (percentage > 25 && percentage < 51) {
      classes = "bg-warning";
    } else if (percentage > 50 && percentage < 76) {
      classes = "bg-info";
    } else if (percentage > 75 && percentage <= 100) {
      classes = "bg-success";
    }
    return classes;
  };

  const columns = [
    {
      name: "OFFER NAMES",
      cell: (row) => {
        const isPercentage = false; // just fornow as things are not cleared yet
        return (
          <div>
            <div className="mb-1 title">{row.offer_name}</div>
            <div className="sub-title mb-1"> address will be here</div>
            <span
              className={`badge me-2 mb-1 rounded-pill text-black ${
                isPercentage ? "bg-label-warning" : "bg-label-info"
              }`}
              style={{
                fontSize: "10px",
                fontWeight: "600",
                color: "#484848",
              }}
            >
              <svg
                className="me-1"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                {isPercentage ? (
                  <>
                    <path
                      d="M7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 1 7 1C3.68629 1 1 3.68629 1 7C1 10.3137 3.68629 13 7 13Z"
                      stroke="#F58423"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9 10C9.55228 10 10 9.55228 10 9C10 8.44772 9.55228 8 9 8C8.44772 8 8 8.44772 8 9C8 9.55228 8.44772 10 9 10Z"
                      stroke="#F58423"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M5 6C5.55228 6 6 5.55228 6 5C6 4.44772 5.55228 4 5 4C4.44772 4 4 4.44772 4 5C4 5.55228 4.44772 6 5 6Z"
                      stroke="#F58423"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M4.3999 9.5999L9.5999 4.3999"
                      stroke="#F58423"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </>
                ) : (
                  <path
                    d="M12.897 1.10297C12.7597 0.965676 12.5371 0.965676 12.3998 1.10297L11.3758 2.12696C11.3128 2.09696 11.2464 2.07279 11.1771 2.05547L7.99493 1.26931C7.63761 1.17999 7.25379 1.28628 6.99333 1.54674L1.30841 7.22231C0.897197 7.63355 0.897197 8.30269 1.30841 8.71394L5.28605 12.6916C5.69729 13.1028 6.36643 13.1028 6.77769 12.6916L12.4533 7.00662C12.7137 6.74616 12.82 6.36234 12.7307 6.00499L11.9445 2.82289C11.9272 2.75356 11.903 2.68711 11.873 2.62416L12.897 1.60018C13.0343 1.46288 13.0343 1.24027 12.897 1.10297ZM12.0485 6.17557C12.0783 6.29468 12.0428 6.4226 11.956 6.50944L6.28044 12.1944C6.14335 12.3314 5.9203 12.3314 5.78321 12.1944L1.80559 8.21672C1.6685 8.07963 1.6685 7.85658 1.80559 7.71949L7.49054 2.04394C7.55703 1.97745 7.64764 1.9411 7.73993 1.9411C7.76815 1.9411 7.79654 1.9445 7.8244 1.95146L10.8134 2.68934L9.96671 3.53602C9.57471 3.34931 9.09052 3.4178 8.76647 3.74185C8.35522 4.1531 8.35522 4.82222 8.76647 5.23348C8.97207 5.43909 9.24218 5.54191 9.51228 5.54191C9.78235 5.54191 10.0525 5.43912 10.2581 5.23348C10.5822 4.90938 10.6506 4.42521 10.4639 4.03322L11.3106 3.18654L12.0485 6.17557ZM9.76088 4.7363C9.62382 4.87336 9.40076 4.87338 9.26368 4.7363C9.12661 4.59924 9.12661 4.37616 9.26368 4.2391C9.33223 4.17054 9.42223 4.1363 9.51228 4.1363C9.60228 4.1363 9.69232 4.17056 9.76083 4.23907L9.76085 4.2391L9.76088 4.23912C9.89796 4.37618 9.89794 4.59924 9.76088 4.7363Z"
                    fill="#2F85FE"
                    stroke="#2F85FE"
                    stroke-width="0.4"
                  />
                )}
              </svg>
              text
            </span>
          </div>
        );
      },

      sortable: true,
      minWidth: "35%",
    },
    {
      name: "OFFERS",
      selector: (row) => row.offer_count,
      sortable: true,
    },
    {
      name: "REDEMPTIONS",
      selector: (row) => row.redemption_count,
      sortable: true,
    },
    {
      name: "ESTIMATED SAVINGS",
      cell: (row) => (
        <div className="w-100">
          <span className="text-muted ">SAR {row.estimated_savings}</span>
          <div className="progress w-100 me-3 my-2" style={{ height: "8px" }}>
            <div
              className={`progress-bar ${getProgressBarClasses(
                row.saving_percentage || 25 //  using this for now as it is not clear yet
              )}`}
              role="progressbar"
              style={{ width: "25%" }}
              aria-valuenow="25"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      ),

      sortable: true,
    },
  ];

  return (
    <>
      <div className="row g-4 mb-4">
        <div className="col-12">
          <div className="card">
            {offerData?.length ? (
              <>
                <div className="card-header d-flex justify-content-between">
                  <h5 className="card-title mb-3">Offers</h5>
                </div>
                <div className="tableHolder">
                  <DataTable
                    className="tableHolderInner"
                    columns={columns}
                    data={offerData}
                    pagination={true}
                    paginationPerPage={5}
                  />
                </div>
              </>
            ) : (
              <div className="row" style={{ height: "calc(100vh - 315px)" }}>
                <div className="col-12">
                  <EmptyListing
                    icon="ti-ticket"
                    title={"No offers available yet"}
                    content={"Explore and request merchants you like to add"}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
