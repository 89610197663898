import React from "react";

export const TopMerchantCard = ({ merchants }) => {
  return (
    <div className="card h-100">
      <div className="card-body">
        <h5>Top 3 Merchants</h5>
        <div className="row three-merchants">
          {merchants?.length ? (
            <>
              {merchants?.map((merchant) => (
                <div className="col-sm-4 col-xl-4 column">
                  <div
                    className="text-center"
                    style={{ fontSize: "12px", color: "#222" }}
                  >
                    <div
                      className="mb-3 mx-auto"
                      style={{
                        width: "48px",
                        height: "48px",
                        border: "1px solid #A8AAAE",
                        borderRadius: "6px",
                        overflow: "hidden",
                      }}
                    >
                      <img
                        src={merchant.logo || "/images/default-profile-img.png"}
                        width="100"
                        height="100"
                        className="img-fluid w-100 h-100"
                        style={{ objectFit: "cover" }}
                      />
                    </div>
                    {merchant.name || ""}
                  </div>
                </div>
              ))}
            </>
          ) : (
            <div>
              <div className="d-flex justify-content-center mb-2 ">
                <i className="ti ti-xl ti-building-store text-primary" />
              </div>
              <div
                className="text-center"
                style={{
                  color: "#A5A3AE",
                  fontSize: "18px",
                  fontWeight: "500",
                }}
              >
                No Merchant available
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
