import React from "react";
import toast, { Toaster } from "react-hot-toast";

export const MembershipDetail = ({ employeeDetail }) => {
  console.log("emplo", employeeDetail);
  return (
    <div className="card mb-4">
      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: "",
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
          },

          // Default options for specific types
          success: {
            duration: 3000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
      <div className="card-body">
        <h5 className="card-title mb-4">Membership Details</h5>
        <ul className="list-unstyled">
          <li className="d-flex align-items-start justify-content-between mb-2">
            <span className="fw-semibold w-50">Membership Expiry</span>
            <span>{employeeDetail.on_boarding_end_date}</span>
          </li>
          <li className="d-flex align-items-start justify-content-between mb-2">
            <span className="fw-semibold w-50">Membership Key</span>
            <span>
              <i
                className="ti ti-copy ti-sm me-2 cursor-pointer"
                onClick={() => {
                  navigator.clipboard.writeText("Key357378");
                  toast.success("Copied to Clipboard!", {
                    duration: 1000,
                  });
                }}
              ></i>{" "}
              {employeeDetail.key}
            </span>
          </li>
          <li className="d-flex align-items-start justify-content-between mb-2">
            <span className="fw-semibold w-50">Onboarding Start Date</span>
            <span>{employeeDetail.on_boarding_start_date}</span>
          </li>
          <li className="d-flex align-items-start justify-content-between mb-2">
            <span className="fw-semibold w-50">Onboarding End Date</span>
            <span>{employeeDetail.on_boarding_end_date}</span>
          </li>
          <li className="d-flex align-items-start justify-content-between mb-2">
            <span className="fw-semibold w-50">
              Onboarding Redemption Count{" "}
            </span>
            <span>{employeeDetail?.total_redemptions}</span>
          </li>
        </ul>
      </div>
    </div>
  );
};
