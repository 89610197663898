import React, { useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { EmptyListing } from "../../Shared/EmptyListing";

export const OutletListing = ({ outletData = [] }) => {
  const [searchText, setSearchText] = useState("");

  const getProgressBarClasses = (percentage) => {
    let classes = "bg-primary";
    if (percentage < 26) {
      classes = "bd-danger";
    } else if (percentage > 25 && percentage < 51) {
      classes = "bg-warning";
    } else if (percentage > 50 && percentage < 76) {
      classes = "bg-info";
    } else if (percentage > 75 && percentage <= 100) {
      classes = "bg-success";
    }
    return classes;
  };

  const columns = [
    {
      name: "OUTLET NAMES",
      selector: (row) => `${row.name}`,
      cell: (row) => (
        <div className="d-flex align-items-center">
          <div className="title">
            {row.name} <span className="sub-title">{row.human_location}</span>
          </div>
        </div>
      ),
      sortable: true,
      minWidth: "35%",
    },
    {
      name: "REDEMPTIONS",
      selector: (row) => row.redemption_count,
      sortable: true,
    },
    {
      name: "ESTIMATED SAVINGS",
      cell: (row) => (
        <div className="w-100">
          <span className="text-muted ">SAR {row.savings_estimate}</span>
          <div className="progress w-100 me-3 my-2" style={{ height: "8px" }}>
            <div
              className={`progress-bar ${getProgressBarClasses(
                row.saving_percentage || 25 //  using this for now as it is not clear yet
              )}`}
              role="progressbar"
              style={{ width: "25%" }}
              aria-valuenow="25"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      ),

      sortable: true,
    },
  ];

  const filteredData = useMemo(() => {
    return outletData?.filter((item) =>
      item?.name?.toLowerCase().includes(searchText.toLowerCase())
    );
  }, [outletData, searchText]);

  return (
    <>
      <div className="row g-4 mb-4">
        <div className="col-12">
          <div className="card">
            {outletData?.length ? (
              <>
                <div className="card-header d-flex justify-content-between">
                  <h5 className="card-title mb-3">Outlets</h5>
                  <div className="dataTable-head d-flex align-items-center">
                    <div className="me-2">Search</div>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Search Project"
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                      style={{ width: "293px" }}
                    />
                  </div>
                </div>
                <div className="tableHolder">
                  <DataTable
                    className="tableHolderInner"
                    columns={columns}
                    data={filteredData}
                    pagination={true}
                    paginationPerPage={6}
                  />
                </div>
              </>
            ) : (
              <div className="row" style={{ height: "calc(100vh - 315px)" }}>
                <div className="col-12">
                  <EmptyListing
                    icon="ti-building-store"
                    title={"There is no merchant outlet"}
                    content={"Explore and request merchants you like to add"}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
