import React from "react";
import { AddKeys } from "./addKeys";
export const KeyComponent = ({ toggleRequestKeyComponent, config }) => {
  return (
    <AddKeys
      toggleRequestKeyComponent={toggleRequestKeyComponent}
      config={config}
    />
  );
};
