import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  config: {},
};

const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    setConfig: (state, action) => {
      state.config = {
        ...state.config,
        ...action.payload,
      };
    },
    clearConfig: (state) => {
      state.config = null;
    },
  },
});

export const { setConfig, clearConfig } = configSlice.actions;
export default configSlice.reducer;
