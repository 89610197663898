import { Offcanvas, Alert } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ApiWrapper } from "../../../Api/apiWrapper";
import {
  REQUEST_FAILED,
  EMPLOYEE_UPDATED,
  INVALID_CODES,
  SUCCESS_CODES,
  EMPLOYEE_ADDED,
} from "../../../Api/constants";
import Select from "react-select";
import { ResponseModal } from "../responseModal";
import { apiEndpoints } from "../../../Api/constants";

export const EditEmployee = ({
  title = "",
  cancelBtnText = "Cancel",
  oKBtnText = "Add",
  onClose,
  endpoint = "",
  employeeDetail = null,
  callback = () => {},
}) => {
  const user = useSelector((state) => state.user.user);
  const localData = JSON.parse(localStorage.getItem("data"));
  const config = useSelector((state) => state.config?.config?.employee || {});

  const [employeeData, setEmployeeData] = useState({
    first_name: "",
    last_name: "",
    employee_id: "",
    email: "",
    mobile_phone: "",
    country_of_residence: "",
    nationality: null,
    tier_user_group: null,
    designation: null,
  });

  const [countryList, setCountryList] = useState([]);
  const [tierList, setTierList] = useState([]);
  const [designationList, setDesignationList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [imageBase64, setImageBase64] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showResponseModal, setShowResponseModal] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  useEffect(() => {
    if (employeeDetail) {
      setEmployeeData(employeeDetail);
    }
  }, [employeeDetail]);

  useEffect(() => {
    fetchResidenceCountry();
    getDropdownData();
  }, []);

  const getDropdownData = async () => {
    // get tier options
    const tiersArray = (config.tiers || [])?.map((tier) => {
      const { tier_title, tier_user_group, ...rest } = tier;
      return { label: tier_title, value: tier_user_group, ...rest };
    });
    const checkKeys = tiersArray?.filter((tier) =>
      employeeDetail && employeeDetail.tier_user_group == tier.value
        ? true
        : tier?.tier_keys > 0
    );
    if (checkKeys?.length > 0) {
      const employeeTiers = checkKeys?.map((tier) => {
        const { tier_keys: valueToRemove, ...rest } = tier;
        return rest;
      });
      setTierList(employeeTiers);
    }

    // get designation options
    const designationsArray = (config.designations || [])?.map(
      (designation) => ({
        label: designation,
        value: designation,
      })
    );
    setDesignationList(designationsArray);
  };

  const fetchResidenceCountry = async () => {
    const requestData = JSON.stringify({
      company_code: user.company_code,
      session_token: localData.session_token,
      locale: localStorage.getItem("i18nextLng"),
    });
    const data = await ApiWrapper({
      path: "/auth/v1/getcountries",
      method: "POST",
      data: requestData,
    });
    if (SUCCESS_CODES.includes(data.status_code)) {
      const updatedArray = data.data?.map((country) => {
        const { id: valueToRemove, ...rest } = country;
        return rest;
      });
      const countryArray = updatedArray?.map((country) => {
        const { name, shortname, ...rest } = country;
        return { label: name, value: shortname, ...rest };
      });
      setCountryList(countryArray);
    } else {
      console.log("something went wrong");
    }
  };

  const onChangeInputData = (key, value) => {
    if (key === "employee_id" || key === "mobile_phone") {
      if (/^\+?\d*$/.test(value)) {
        setEmployeeData({
          ...employeeData,
          [key]: value,
        });
      }
    } else {
      setEmployeeData({
        ...employeeData,
        [key]: value,
      });
    }
  };

  const handleSave = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const requestData = JSON.stringify({
        company_code: user.company_code,
        session_token: localData.session_token,
        locale: localStorage.getItem("i18nextLng"),
        first_name: employeeData.first_name,
        last_name: employeeData.last_name,
        employee_id: employeeData.employee_id,
        designation: employeeData.designation,
        email: employeeData.email,
        mobile_phone: employeeData.mobile_phone,
        country_of_residence: employeeData.country_of_residence,
        nationality: employeeData.nationality,
        tier_user_group: employeeData.tier_user_group,
        ...(imageBase64 && {
          profile_image: imageBase64,
        }),
        ...(employeeDetail && {
          employee_pk: employeeData.id,
        }),
      });
      const data = await ApiWrapper({
        path: apiEndpoints[endpoint],
        method: "POST",
        data: requestData,
      });
      if (INVALID_CODES.includes(data.status_code) || data.errorMessage) {
        setErrorMessage(
          data.errorMessage || data.message || "Something went wrong!"
        );
      } else if (SUCCESS_CODES.includes(data.status_code)) {
        callback();
      }
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false);
      setShowResponseModal(true);
    }
  };

  const handleImageChange = (event) => {
    const file = event?.target?.files[0];
    if (!file) return;
    const fileSize = Math.round(file.size / 1024);
    if (fileSize > 800) {
      setAlertMessage("Size Exceeded! Please Select image upto 800kb");
      return;
    }
    var reader = new FileReader();
    var base64String;
    reader.onloadend = function () {
      base64String = reader.result;
      setImageBase64(base64String);
    };
    reader.readAsDataURL(file);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    onClose();
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: "1px solid #dbdade", // Border color
      borderRadius: "6px", // Border radius
      cursor: "pointer",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#80C100" : "white", // Background color on hover/focus
      color: state.isFocused ? "white" : "black", // Text color on hover/focus
      boxShadow: state.isFocused ? "none" : "none", // Add box shadow on focus
      borderRadius: "6px",
    }),
    menu: (provided) => ({
      ...provided,
      boxShadow: "0 0 0 1px rgba(0,0,0,0.1)", // Add box shadow on focus
      border: "0", // Border color for the menu
      borderRadius: "6px", // Border radius for the menu
      padding: "10px",
    }),
  };

  return (
    <>
      <div className={`custom-backdrop`} />
      <Offcanvas show={true} onHide={onClose} placement="end" backdrop={false}>
        {alertMessage && (
          <Alert
            variant="warning"
            onClose={() => setAlertMessage("")}
            dismissible
            className="position-absolute"
            style={{ zIndex: "99" }}
          >
            {alertMessage}
          </Alert>
        )}
        {loading ? (
          <div className="pageLoader d-flex align-items-center justify-content-center position-absolute">
            <div className="spinner-border spinner-border-lg text-primary"></div>
          </div>
        ) : (
          ""
        )}
        {showResponseModal ? (
          <ResponseModal
            toggleMainComponent={onClose}
            operation={
              errorMessage
                ? REQUEST_FAILED
                : employeeDetail
                ? EMPLOYEE_UPDATED
                : EMPLOYEE_ADDED
            }
            heading={
              errorMessage
                ? "Error"
                : employeeDetail
                ? "Information Updated"
                : "Employee Added"
            }
            message={
              errorMessage
                ? errorMessage
                : employeeDetail
                ? "Employee profile information has been successfully Updated"
                : "Link has been sent to the user's registered email address"
            }
          />
        ) : (
          <div className="d-flex flex-column h-100">
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>{title}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div className="user-avatar-section mb-3">
                <div className="d-flex align-items-start">
                  <div className="userProfileImg me-4">
                    <img
                      src={
                        imageBase64 ||
                        employeeData.profile_image ||
                        "/images/default-profile-img.png"
                      }
                      alt="User Profile image"
                      width="100"
                      height="100"
                      className="img-fluid"
                    />
                  </div>
                  <div className="user-info">
                    <div className="custom-file-upload mb-3">
                      <button className="btn btn-primary">
                        Upload new photo
                      </button>
                      <input
                        type="file"
                        id="fileInput"
                        className="file-btn"
                        value={""}
                        onChange={(file) => handleImageChange(file)}
                        accept="image/x-png,image/gif,image/jpeg"
                      />
                    </div>
                    <p className="text-muted mb-0">
                      Allowed JPG, GIF or PNG. Max size of 800K
                    </p>
                  </div>
                </div>
              </div>
              <form onSubmit={handleSave}>
                <div>
                  <div className="mb-3">
                    <label htmlFor="languageSelect" className="form-label">
                      Tiers
                    </label>
                    <Select
                      name="tier"
                      styles={customStyles}
                      value={tierList?.filter(
                        (tier) => employeeData.tier_user_group == tier.value
                      )}
                      options={tierList}
                      onChange={(val) =>
                        onChangeInputData("tier_user_group", val.value)
                      }
                      required
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="first-name" className="form-label">
                      First Name
                    </label>
                    <input
                      required
                      type="text"
                      className="form-control"
                      id="first-name"
                      name="firstName"
                      value={employeeData.first_name}
                      onChange={(event) =>
                        onChangeInputData("first_name", event.target.value)
                      }
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="last-name" className="form-label">
                      Last Name
                    </label>
                    <input
                      required
                      type="text"
                      className="form-control"
                      id="last-name"
                      name="lastName"
                      value={employeeData.last_name}
                      onChange={(event) =>
                        onChangeInputData("last_name", event.target.value)
                      }
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="userID" className="form-label">
                      Employee ID
                    </label>
                    <input
                      required
                      type="text"
                      className="form-control"
                      id="userID"
                      name="employeeId"
                      value={employeeData.employee_id}
                      onChange={(event) =>
                        onChangeInputData("employee_id", event.target.value)
                      }
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="languageSelect" className="form-label">
                      Designation
                    </label>
                    <Select
                      styles={customStyles}
                      value={designationList?.filter(
                        (desig) => employeeData.designation == desig.value
                      )}
                      options={designationList}
                      onChange={(val) =>
                        onChangeInputData("designation", val.value)
                      }
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                      Email
                    </label>
                    <input
                      required
                      type="email"
                      className="form-control"
                      id="email"
                      name="email"
                      value={employeeData.email}
                      disabled={employeeDetail ? true : false}
                      onChange={(event) =>
                        onChangeInputData("email", event.target.value)
                      }
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="phone" className="form-label">
                      Phone
                    </label>
                    <input
                      required
                      type="tel"
                      className="form-control"
                      id="phone"
                      name="phone"
                      value={employeeData.mobile_phone}
                      onChange={(event) =>
                        onChangeInputData("mobile_phone", event.target.value)
                      }
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="languageSelect" className="form-label">
                      Country Of Residence
                    </label>
                    <Select
                      styles={customStyles}
                      menuPlacement="top"
                      value={countryList?.filter(
                        (country) =>
                          employeeData.country_of_residence == country.value
                      )}
                      options={countryList}
                      onChange={(val) =>
                        onChangeInputData("country_of_residence", val.value)
                      }
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="nationalitySelect" className="form-label">
                      Nationality
                    </label>
                    <Select
                      menuPlacement="top"
                      styles={customStyles}
                      value={countryList?.filter(
                        (country) => employeeData.nationality == country.value
                      )}
                      options={countryList}
                      onChange={(val) =>
                        onChangeInputData("nationality", val.value)
                      }
                      required
                    />
                  </div>
                </div>
                <div className="bottom-btnWrap d-flex justify-content-between gap-3 position-absolute bottom-0 start-0 end-0 pb-4 ps-4 pe-4">
                  <button className="btn btn-primary d-grid w-100">
                    {oKBtnText}
                  </button>
                  <button
                    className="btn-label-secondary d-grid w-100 btn"
                    onClick={handleCancel}
                  >
                    {cancelBtnText}
                  </button>
                </div>
              </form>
            </Offcanvas.Body>
          </div>
        )}
      </Offcanvas>
    </>
  );
};
