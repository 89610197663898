import React from 'react'
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {useTranslation} from 'react-i18next'

export const CategoriesBreakDown = ({categoryData}) => {
  
  const {t} = useTranslation();

  return(
    <div className="col-md-6 mb-4">
      <div className="card h-100">
        <div className="card-header d-flex justify-content-between">
          <div className="card-title mb-0">
            <h5 className="mb-0">{t("category_breakdown.category_title")}</h5>
          </div>
        </div>
        <div className="card-body pb-0">
          <ul className="list-unstyled mb-0">
            {categoryData?.categories?.map((category, index) => (
              <li className="d-flex mb-4 pb-1 align-items-center" key={index}>
                <div className="avatar flex-shrink-0 me-3">
                  <span className="avatar-initial rounded bg-label-primary">
                    <img src={category.image} alt="Reward Index" className="img-fluid"/>
                  </span>
                </div>
                <div className="row w-100 align-items-center">
                  <div className="col-8 mb-1 mb-sm-0 mb-lg-1 mb-xxl-0">
                    <p className="mb-0 fw-medium">{category.name}</p>
                  </div>
                  <div
                    className="col-4 d-flex justify-content-md-end justify-content-start align-items-center">
                    <span>{category.merchants_percentage ?? 0}%</span>
                    <span style={{ width: 35, height: 35 }}
                      className="ms-3"
                      >
                      <CircularProgressbar
                        value={category.merchants_percentage}
                        styles={{
                          path: {
                            stroke: category.loader_color,
                          },
                        }}
                      />
                    </span>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}