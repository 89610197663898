import React, { useState } from "react";
import { ApiWrapper } from "../../Api/apiWrapper";
import { SUCCESS_CODE, SUCCESS_CODES } from "../../Api/constants";
import { useSelector } from "react-redux";

export const ChangePassword = () => {
  const user = useSelector((state) => state.user.user);
  //const userProfile = user.profile;

  let errors = {};
  const [validateCP, setValidateCP] = useState("");
  // const [showError, setShowError] = useState("none");
  const [showError, setShowError] = useState(false);
  const [validateCPColor, setValidateCPColor] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const validatePassword = (event) => {
    const newPwd = event.target.value;
    //setPassword(newPwd);

    // Regular expressions to check for at least one digit and one special character
    const digitRegex = /\d/;
    const specialCharRegex = /[^a-zA-Z0-9]/;
    const upperCaseRegex = /[A-Z]/;
    const lowerCaseRegex = /[a-z]/;

    // Check if the password meets the criteria
    const isLengthValid = newPwd?.length >= 8 && newPwd?.length <= 20;
    const hasDigit = digitRegex.test(newPwd);
    const hasSpecialChar = specialCharRegex.test(newPwd);
    const hasUpperCase = upperCaseRegex.test(newPwd);
    const hasLowerCase = lowerCaseRegex.test(newPwd);

    if (newPwd?.length !== 0) {
      if (
        isLengthValid &&
        hasDigit &&
        hasSpecialChar &&
        hasUpperCase &&
        hasLowerCase
      ) {
        setValidateCP("Valid Password");
        setValidateCPColor("green");
        errors.passwordSuccess = false;
        errors.password = false;
        setFormErrors(errors);
      } else if (
        !(
          isLengthValid &&
          hasDigit &&
          hasSpecialChar &&
          hasUpperCase &&
          hasLowerCase
        )
      ) {
        setValidateCP("InValid Password");
        setValidateCPColor("red");
        errors.passwordSuccess = false;
        errors.password = false;
        setFormErrors(errors);
      }
    } else {
      setShowError("none");
    }
  };
  function isPasswordValid(password) {
    // Minimum 8 characters long, at least one uppercase letter, one digit, and one symbol
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(password);
  }
  const showValidationError = () => {
    setShowError("block");
  };
  const currentPasswordChangeHandler = (event) => {
    // validatePassword(event);
    setCurrentPassword(event.target.value);
  };
  const newPasswordChangeHandler = (event) => {
    let isValid = isPasswordValid(event.target.value);

    setShowError(!isValid);

    setNewPassword(event.target.value);
  };

  const confirmPasswordChangeHandler = (event) => {
    setConfirmPassword(event.target.value);
  };

  const validateSetPasswordForm = () => {
    // Password validation
    if (!currentPassword) {
      errors.currentPassword = "Current Password field is required";
    }
    if (!newPassword) {
      errors.newPassword = "New Password field is required";
    }
    if (!confirmPassword) {
      errors.confirmPassword = "Confirm Password field is required";
    }

    // password match
    if (newPassword !== confirmPassword) {
      errors.confirmPassword = "Password does not match";
    }

    // Set formErrors state
    setFormErrors(errors);
    // Return true if there are no errors
    return Object.keys(errors)?.length === 0;
  };

  const onSetPasswordHandler = async (event) => {
    event.preventDefault();
    try {
      const localData = JSON.parse(localStorage.getItem("data"));
      // event.preventDefault();
      if (validateSetPasswordForm()) {
        setLoading(true);
        //   const urlParams = new URLSearchParams(window.location.search);
        const requestData = JSON.stringify({
          company_code: user.company_code,
          session_token: localData.session_token,
          current_password: currentPassword,
          new_password: newPassword,
          confirm_password: confirmPassword,
          locale: localStorage.getItem("i18nextLng"),
        });
        const data = await ApiWrapper({
          path: "/auth/v1/changepassword",
          method: "POST",
          data: requestData,
        });
        if (SUCCESS_CODES.includes(data.status_code)) {
          errors.passwordSuccess = data.message;
          setFormErrors(errors);
          setShowError("none");
          setShowError(false);
        } else {
          errors.password = data.message;
          setFormErrors(errors);
          setShowError("none");
        }
      }
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false);
      // setCurrentPassword("");
      // setNewPassword("");
      // setConfirmPassword("");
    }
  };
  return (
    <>
      <div className="card mb-4">
        <h5 className="card-header">Change Password</h5>
        <div className="card-body">
          <form onSubmit={onSetPasswordHandler}>
            <div className="alert alert-warning" role="alert">
              <h5 className="alert-heading mb-2">
                Ensure that these requirements are met
              </h5>
              <span>
                Minimum 8 characters long, uppercase & symbol & One Digit
              </span>
              {/* <p style={{ color: validateCPColor, display: showError }}>
                {validateCP}
              </p> */}

              {newPassword && (
                <p style={{ color: showError ? "red" : "green" }}>
                  {showError ? "InValid Password" : "Valid Password"}
                </p>
              )}
              {formErrors.password && (
                <p className="error-color">{formErrors.password}</p>
              )}
              {formErrors.passwordSuccess && (
                <p style={{ color: "green" }}>{formErrors.passwordSuccess}</p>
              )}
            </div>
            <div className="row">
              <div className="mb-3 col-12 form-password-toggle">
                <label className="form-label" htmlFor="currentPassword">
                  Current Password
                </label>
                <div className="input-group input-group-merge">
                  <input
                    className="form-control"
                    type="password"
                    id="currentPassword"
                    name="currentPassword"
                    placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                    value={currentPassword}
                    onChange={currentPasswordChangeHandler}
                    // onBlur={showValidationError}
                  />
                </div>
                {formErrors.currentPassword && (
                  <p className="error-color">{formErrors.currentPassword}</p>
                )}
              </div>
              <div className="mb-3 col-12 col-sm-6 form-password-toggle">
                <label className="form-label" htmlFor="newPassword">
                  New Password
                </label>
                <div className="input-group input-group-merge">
                  <input
                    className="form-control"
                    type="password"
                    id="newPassword"
                    name="newPassword"
                    placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                    value={newPassword}
                    onChange={newPasswordChangeHandler}
                  />
                </div>
                {formErrors.newPassword && (
                  <span className="error-color">{formErrors.newPassword}</span>
                )}
              </div>

              <div className="mb-3 col-12 col-sm-6 form-password-toggle">
                <label className="form-label" htmlFor="confirmPassword">
                  Confirm Password
                </label>
                <div className="input-group input-group-merge">
                  <input
                    className="form-control"
                    type="password"
                    name="confirmPassword"
                    id="confirmPassword"
                    placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                    value={confirmPassword}
                    onChange={confirmPasswordChangeHandler}
                  />
                </div>
                {formErrors.confirmPassword && (
                  <span className="error-color">
                    {formErrors.confirmPassword}
                  </span>
                )}
              </div>
              <div>
                <button className="btn btn-primary me-2">
                  Change Password
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {loading ? (
        <div className="pageLoader d-flex align-items-center justify-content-center">
          <div className="spinner-border spinner-border-lg text-primary"></div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
