import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ApiWrapper } from "../../Api/apiWrapper";
import { useTranslation } from "react-i18next";
import { INVALID_CODE, SUCCESS_CODE } from "../../Api/constants";
import { useDispatch } from "react-redux";
import { setUser } from "../../features/userSlice";
import { setModule } from "../../features/moduleSlice";

const PASSWORD_LENGTH = 4;
const Login = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  let errors = {};
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [formErrors, setFormErrors] = useState({});
  const [isValid, setIsValid] = useState(true);
  const [loading, setLoading] = useState(false);

  const validateLoginForm = () => {
    // let errors = {};

    // Validate email
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!formData?.email?.trim()) {
      errors.email = "Email field is required";
    }
    if (formData?.email && !emailPattern.test(formData?.email)) {
      errors.email = "Please enter a  valid email";
    }
    // Password validation
    if (!formData?.password) {
      errors.password = "Password field is required";
    }
    if (formData?.password && formData?.password?.length < PASSWORD_LENGTH) {
      errors.password = "Password must be at least 4 characters";
    }

    // Set formErrors state
    setFormErrors(errors);
    // Return true if there are no errors
    return Object.keys(errors)?.length === 0;
  };

  const InputChangeHandler = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const onLoginHandler = (event) => {
    event.preventDefault();
    if (validateLoginForm()) {
      setIsValid(true);
      setLoading(true);
      const credentials = JSON.stringify({
        email: formData.email,
        password: formData.password,
        locale: localStorage.getItem("i18nextLng"),
      });
      (async () => {
        const data = await ApiWrapper({
          path: "/auth/v1/login",
          method: "POST",
          data: credentials,
        });
        if (data.status_code === SUCCESS_CODE) {
          localStorage.setItem("is_logIn", "1");
          localStorage.setItem("data", JSON.stringify(data.data.session));
          dispatch(setUser(data.data.profile));
          dispatch(setModule(data.data.profile.modules));
          setLoading(false);
          navigate("/dashboard");
        } else {
          if (data.status_code === INVALID_CODE) {
            setIsValid(false);
            setLoading(false);
          }
        }
      })();
    }
  };
  return (
    <>
      <div className="container-xxl">
        <div className="authentication-wrapper authentication-basic container-p-y">
          <div className="authentication-inner py-4">
            {!isValid && (
              <div
                className="alert alert-danger d-flex align-items-center"
                role="alert"
              >
                <span className="alert-icon text-danger me-2">
                  <i className="ti ti-ban ti-xs"></i>
                </span>
                {t("login_screen.form_fields.error_messages.email_password")}
              </div>
            )}
            <div className="card">
              <div className="card-body">
                <div className="app-brand justify-content-center mb-4 mt-2">
                  <span className="app-brand-link">
                    <img
                      src="/images/app-logo-vertical.png"
                      alt="Reward Index"
                      className="img-fluid"
                    />
                  </span>
                </div>
                <h4 className="mb-1 pt-2">{t("login_screen.lets_started")}</h4>
                <p className="mb-4">
                  {t("login_screen.continue_to_your_account")}
                </p>

                <form onSubmit={onLoginHandler}>
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                      {t("login_screen.form_fields.email_username")}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="email"
                      name="email"
                      placeholder="Enter your email or username"
                      value={formData.email}
                      onChange={InputChangeHandler}
                    />
                    {formErrors.email && (
                      <span className="error-color">{formErrors.email}</span>
                    )}
                  </div>
                  <div className="mb-3 form-password-toggle">
                    <div className="d-flex justify-content-between">
                      <label className="form-label" htmlFor="password">
                        {t("login_screen.form_fields.password")}
                      </label>
                      <Link to="/forget-password" className="auth-link">
                        <small>
                          {t("login_screen.form_fields.forgot_password")}
                        </small>
                      </Link>
                    </div>
                    <div className="input-group input-group-merge">
                      <input
                        type="password"
                        id="password"
                        className="form-control"
                        name="password"
                        placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                        aria-describedby="password"
                        value={formData.password}
                        onChange={InputChangeHandler}
                      />
                      {/*<span className="input-group-text cursor-pointer">*/}
                      {/*  <i className="ti ti-eye-off"></i>*/}
                      {/*</span>*/}
                    </div>
                    {formErrors.password && (
                      <span className="error-color">{formErrors.password}</span>
                    )}
                  </div>
                  <div className="mb-3">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="remember-me"
                      />
                      <label className="form-check-label" htmlFor="remember-me">
                        {t("login_screen.form_fields.remember_me")}
                      </label>
                    </div>
                  </div>
                  <div>
                    <button
                      className="btn btn-primary d-grid w-100"
                      type="submit"
                    >
                      {t("login_screen.form_fields.sign_in")}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {loading ? (
        <div className="pageLoader d-flex align-items-center justify-content-center">
          <div className="spinner-border spinner-border-lg text-primary"></div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Login;
