import React from "react";
import { Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export const RemoveAccess = ({
  title = "",
  message = "",
  cancelBtnText = "Cancel",
  okBtnText = "Ok",
  handleClose = () => {},
  handleOkBtn = () => {},
  loading = false,
}) => {
  const handleCloseModal = () => {
    !loading && handleClose();
  };

  return (
    <>
      <Modal
        centered
        show={true}
        onHide={handleCloseModal}
        backdrop={loading ? "static" : true}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{message}</Modal.Body>
        <Modal.Footer className="pt-4">
          <Button
            variant="secondary"
            className="btn-label-secondary"
            onClick={handleCloseModal}
            disabled={loading}
          >
            {cancelBtnText}
          </Button>
          <Button variant="primary" onClick={handleOkBtn} disabled={loading}>
            {loading && (
              <Spinner animation="border" size="sm" className="me-2" />
            )}
            {okBtnText}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
