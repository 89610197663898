import React, { useEffect, useState } from "react";
import { Alert, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { ApiWrapper } from "../../../Api/apiWrapper";
import {
  SUCCESS_CODES,
  INVALID_CODES,
  apiEndpoints,
} from "../../../Api/constants";

export const LoginInfo = ({ isEmailVerified, employee_id }) => {
  const user = useSelector((state) => state.user.user);
  const localData = JSON.parse(localStorage.getItem("data"));

  const [btnLoading, setBtnLoading] = useState({});
  const [alertMessage, setAlertMessage] = useState({});

  // const handleAlertMessage = (stateSetter, type, message, timer) => {
  //   stateSetter({
  //     type,
  //     message,
  //   });
  //   setTimeout(() => {
  //     stateSetter({});
  //   }, timer || 3000);
  // };

  const handleSendResetLink = async (type) => {
    setBtnLoading({ [type]: true });
    try {
      const requestData = JSON.stringify({
        company_code: user.company_code,
        session_token: localData.session_token,
        locale: localStorage.getItem("i18nextLng"),
        employee_pk: employee_id,
      });
      const data = await ApiWrapper({
        path:
          type === "email"
            ? apiEndpoints["verify_email"]
            : apiEndpoints["reset_password_link"],
        method: "POST",
        data: requestData,
      });
      if (INVALID_CODES.includes(data.status_code) || data.errorMessage) {
        setAlertMessage({
          type: "error",
          message: data.errorMessage || data.message || "Something went wrong!",
        });
      } else if (SUCCESS_CODES.includes(data.status_code)) {
        setAlertMessage({
          type: "success",
          message: data.message || "Link has been sent successfully!",
        });
      }
    } catch (error) {
      console.log({ error });
    } finally {
      setBtnLoading({});
    }
  };
  console.log("alertMe", alertMessage);
  return (
    <div className="card mb-4">
      {alertMessage.message && (
        <Alert
          variant={alertMessage.type === "error" ? "danger" : "success"}
          onClose={() => setAlertMessage("")}
          dismissible
          className="position-absolute w-100"
          style={{ zIndex: "99" }}
        >
          {alertMessage.message}
        </Alert>
      )}
      <div className="card-body">
        <h5 className="card-title mb-4">Login Info</h5>
        <div className="mb-2">
          <h6 className="mb-1">Password</h6>
          <div className="row align-items-start">
            <div className="col-md-8 col-12">
              <p className="text-muted small mb-0">
                Send reset password link to user email{" "}
              </p>
            </div>
            <div className="col-md-4 col-12 text-end">
              <button
                type="button"
                class="btn btn-outline-dark waves-effect pt-2 pb-2 small"
                onClick={() => handleSendResetLink("reset-password")}
                disabled={Object.keys(btnLoading)?.length}
              >
                {btnLoading["reset-password"] && (
                  <Spinner animation="border" size="sm" className="me-2" />
                )}
                Send Link
              </button>
            </div>
          </div>
        </div>
        <div className="mb-2">
          <h6 className="mb-1">Email</h6>
          <div className="row align-items-start">
            <div className="col-md-8 col-12">
              <p className="text-muted small mb-0">
                Send email verification link{" "}
              </p>
            </div>
            <div className="col-md-4 col-12 text-end">
              {isEmailVerified ? (
                <span className="badge bg-label-success">
                  {"Email is verified"}
                </span>
              ) : (
                <button
                  type="button"
                  class="btn btn-outline-dark waves-effect pt-2 pb-2 small"
                  onClick={() => handleSendResetLink("email")}
                  disabled={Object.keys(btnLoading)?.length}
                >
                  {btnLoading["email"] && (
                    <Spinner animation="border" size="sm" className="me-2" />
                  )}
                  Send Link
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
