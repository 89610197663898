import React, { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { SUCCESS_CODES } from "../Api/constants";
import { ApiWrapper } from "../Api/apiWrapper";
import { useSelector } from "react-redux";
import { MerchantInfo } from "../Components/Merchant/merchantProfile/merchantInfo";
import { useParams } from "react-router-dom";
import { OutletListing } from "../Components/Merchant/merchantProfile/outletListing";
import { OfferListing } from "../Components/Merchant/merchantProfile/offersListing";

export const MerchantProfile = () => {
  const { id } = useParams();

  const user = useSelector((state) => state.user.user);
  const localData = JSON.parse(localStorage.getItem("data"));
  const [merchantData, setMerchantData] = useState({});
  const [outletData, setOutletData] = useState([]);
  const [offerData, setOfferData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    try {
      const requestData = JSON.stringify({
        company_code: user.company_code,
        session_token: localData.session_token,
        locale: localStorage.getItem("i18nextLng"),
        merchant_id: id,
      });

      const apiEndPoints = [
        "/merchant/v1/getoutletslist",
        "/merchant/v1/getofferslist",
        "/merchant/v1/getmerchantdetails",
      ];

      setLoading(true);
      const apiPromises = apiEndPoints?.map((endpoint) =>
        ApiWrapper({
          path: endpoint,
          method: "POST",
          data: requestData,
        })
      );

      Promise.allSettled(apiPromises).then((response) => {
        setOutletData(response[0]?.value?.data?.outlets || []);
        setOfferData(response[1]?.value?.data?.offers || []);
        setMerchantData(response[2]?.value?.data || {});
        setLoading(false);
      });
    } catch (error) {
      console.log({ error });
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <div className="pageLoader d-flex align-items-center justify-content-center">
          <div className="spinner-border spinner-border-lg text-primary"></div>
        </div>
      ) : (
        <div className="layout-page">
          <div className="content-wrapper">
            <div className="container-fluid container-p-y">
              <div className="row g-4">
                <MerchantInfo merchantDetail={merchantData} />
                <div className="col-lg-8 col-md-8 order-0 order-md-1">
                  <Tabs
                    defaultActiveKey="security"
                    id="uncontrolled-tab-example"
                    className="nav nav-pills tabs bg-transparent border-0 flex-column flex-md-row mb-4"
                  >
                    <Tab
                      className="nav-item"
                      eventKey="security"
                      title={<span>Outlets</span>}
                    >
                      <OutletListing outletData={outletData} />
                    </Tab>
                    <Tab
                      className="nav-item"
                      eventKey="notifications"
                      title={<span>Offers</span>}
                    >
                      <OfferListing offerData={offerData} />
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
