import React from "react";

export const EmptyListing = ({
  icon = "ti-users",
  iconClasses = "text-primary",
  title,
  content,
  actions: Actions = null,
}) => {
  return (
    <div className="card h-100 align-items-center justify-content-center">
      <div className="content_noEmployee">
        <div className="icon d-flex align-items-center justify-content-center mx-auto mb-4">
          <i
            className={`menu-icon tf-icons ti ti-xl m-0 ${icon} ${iconClasses}`}
          />
        </div>
        <h3 className="card-title mb-3">{title}</h3>
        <p className="text-muted mb-4">{content}</p>
        {Actions && (
          <div className="row">
            <Actions />
          </div>
        )}
      </div>
    </div>
  );
};
