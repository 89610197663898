import { Routes, Route, useNavigate, Outlet } from "react-router-dom";
import Login from "./Components/Auth/login";
import { useEffect, useRef } from "react";
import { getToken } from "./Api/apiWrapper";
import { Dashboard } from "./Pages/Dashboard";
import { Profile } from "./Pages/Profile";
import { SideBar } from "./Components/Ui/Navigation/sideBar";
import { TopBar } from "./Components/Ui/Navigation/topBar";
import { ForgetPassword } from "./Components/Auth/forgetPassword";
import { SetPassword } from "./Components/Auth/setPassword";
import { Employee } from "./Pages/Employee";
import { Merchant } from "./Pages/Merchant";
import { Content } from "./Pages/Content";
import { EmployeeProfile } from "./Pages/employeeProfile";
import { MerchantProfile } from "./Pages/merchantProfile";

function App() {

  const parentDivRef = useRef(null);


  const navigation = useNavigate();
  useEffect(() => {
    const path = window.location.pathname;
    if (path === "/" && !localStorage.getItem("is_logIn")) {
      navigation("/login");
    } else {
      if (path === "/forget-password" && !localStorage.getItem("is_logIn")) {
        navigation("/forget-password");
      } else {
        if (localStorage.getItem("is_logIn")) {
          if (path === "/profile") {
            navigation("/profile");
          }
          if (path === "/employee") {
            navigation("/employee");
          }
          if (path === "/employee/detail/:id") {
            navigation("/employee/detail/:id");
          } else {
            navigation("/dashboard");
          }
        } else {
          const urlParams = new URLSearchParams(window.location.search);
          if (!urlParams.get("token")) {
            navigation("/login");
          }
        }
      }
    }
    getToken();
  }, []);

  const openSider = () => {
    if (parentDivRef.current) {
      parentDivRef.current.classList.add("layout-menu-expanded");
    }
  };
  const closeSider = () => {
    if (parentDivRef.current) {
      parentDivRef.current.classList.remove("layout-menu-expanded");
    }
  };


  const AppLayout = () => (
    <>
      {localStorage.getItem("is_logIn") && (
        <>
          <SideBar closeSider = {closeSider} />
          <TopBar openSider = {openSider}/>
          <Outlet />
        </>
      )}
    </>
  );

  return (
    <>
      <div ref={parentDivRef} className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Routes>
            <Route path="/login" exact element={<Login />}></Route>
            <Route
              path="/forget-password"
              exact
              element={<ForgetPassword />}
            ></Route>
            <Route path="/set-password" exact element={<SetPassword />}></Route>
            <Route element={<AppLayout />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/employee" element={<Employee />} />
              <Route
                path="/employee/detail/:id"
                element={<EmployeeProfile />}
              />
              <Route path="/merchants" element={<Merchant />} />
              <Route
                path="/merchant/detail/:id/:name"
                element={<MerchantProfile />}
              />
              <Route path="/content" element={<Content />} />
            </Route>
          </Routes>
        </div>
      </div>
    </>
  );
}

export default App;
