import React, { useEffect, useState } from "react";

import { BarChart, Bar, XAxis, ResponsiveContainer, Tooltip } from "recharts";
import { NISSAN_COMPANY_CODE, SUCCESS_CODE } from "../../../Api/constants";
import { ApiWrapper } from "../../../Api/apiWrapper";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const data = [
  {
    name: "Jan",
    saving: 4000,
  },
  {
    name: "Fab",
    saving: 3000,
  },
  {
    name: "Mar",
    saving: 2000,
  },
  {
    name: "Apr",
    saving: 2780,
  },
  {
    name: "May",
    saving: 18,
  },
  {
    name: "Jun",
    saving: 2390,
  },
  {
    name: "Jul",
    saving: 3490,
  },
  {
    name: "Aug",
    saving: 3490,
  },
  {
    name: "Sep",
    saving: 3490,
  },
  {
    name: "Oct",
    saving: 3490,
  },
  {
    name: "Nov",
    saving: 3490,
  },
  {
    name: "Dec",
    saving: 3490,
  },
];

const renderCustomizedLabel = (props) => {
  const { x, y, width, height, value } = props;
  const radius = 10;

  return (
    <g>
      {/*<circle cx={x + width / 2} cy={y - radius} r={radius} fill="#8884d8" />*/}
      <text
        x={x + width / 2}
        y={y - radius}
        fill="#000000"
        textAnchor="middle"
        dominantBaseline="middle"
      >
        {"25k"}
      </text>
    </g>
  );
};

export const EstimatedSavings = () => {
  const { t } = useTranslation();

  const [estimatedSavings, setEstimatedSavings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalEstimatedSavings, setTotalEstimatedSavings] = useState("");
  const user = useSelector((state) => state.user.user);

  const today = new Date();
  const getFullYear = today.getFullYear();
  const yearStart = new Date(getFullYear, 0, 1);
  const yearEnd = new Date(getFullYear, 11, 31);
  const [year, setYear] = useState(getFullYear);

  // Create an array of the previous 10 years
  const previousTenYears = Array.from(
    { length: 10 },
    (_, index) => getFullYear - index
  );

  const fetchEstimatedSavings = async (sDate, eDate) => {
    const localData = JSON.parse(localStorage.getItem("data"));
    const requestData = JSON.stringify({
      company_code: user?.company_code,
      session_token: localData.session_token,
      locale: localStorage.getItem("i18nextLng"),
      start_date:
        sDate.getFullYear() +
        "-" +
        (sDate.getMonth() + 1) +
        "-" +
        sDate.getDate(),
      end_date:
        eDate.getFullYear() +
        "-" +
        (eDate.getMonth() + 1) +
        "-" +
        eDate.getDate(),
    });
    const data = await ApiWrapper({
      path: "/dashboard/v1/getsavingsanalytics",
      method: "POST",
      data: requestData,
    });
    if (data.status_code === SUCCESS_CODE) {
      // const jsonData = JSON.stringify(data.data);
      // const parsedData = JSON.parse(jsonData);
      const parsedData = data.data;
      let updatedObject = [];
      let i = 0;
      let count = 0;
      Object.keys(parsedData.savings).forEach((oldKey) => {
        const newKey = oldKey;
        updatedObject[newKey] = parsedData.savings[oldKey];
        updatedObject[newKey]["name"] = oldKey;
        updatedObject = Object.values(updatedObject);
        count = count + updatedObject[i].saving;
        i++;
      });
      setTotalEstimatedSavings(`SAR ${count.toLocaleString()}`);
      setEstimatedSavings(updatedObject);
      setLoading(false);
    } else {
      console.log("something went wrong");
    }
  };
  useEffect(() => {
    fetchEstimatedSavings(yearStart, yearEnd);
  }, []);

  const savingsYearHandler = (event) => {
    const year = event.target.value;
    const firstDate = new Date(year, 0, 1);
    const lastDate = new Date(year, 11, 31);
    setYear(event.target.value);
    fetchEstimatedSavings(firstDate, lastDate);
  };
  const getBarColor = (savings) => {
    const month = today.toLocaleString("default", { month: "short" });
    if (month === savings.name) {
      return "#80C100";
    }
    // if (entry.name === 'Category 3') {
    //   return '#FF5733';
    // }
    // return '#D3E9A6';
  };
  const renderCustomBarLabel = ({ payload, x, y, width, height, value }) => {
    return (
      <text
        x={x + width / 2}
        y={y}
        textAnchor="middle"
        dy={-6}
      >{`${value}`}</text>
    );
  };

  const handleBar = () => {
    console.log("Hey");
  };
  console.log(estimatedSavings);
  return (
    <div className="row g-4 mb-4">
      <div className="col-12">
        <div className="card">
          <div className="card-header header-elements mb-5">
            <div>
              <h5 className="card-title mb-0">
                {t("estimated_saving.est_title")}
              </h5>
              <p className="m-0">
                <small className="text-muted">
                  {t("estimated_saving.sub_est_title")}
                </small>
              </p>
            </div>
            <div className="card-action-element d-flex ms-auto py-0">
              <div className="savingHead d-flex align-items-center me-3">
                <span className="badge bg-label-lighGreen text-primary rounded p-2">
                  <i className="ti ti-pig ti-sm"></i>
                </span>
                <h4 className="mb-0 ms-3">{totalEstimatedSavings}</h4>
              </div>
              {/* <div className='selectHolder ms-5'> */}
              {/*<select className="form-select" style={ { minWidth: '124px' } }>*/}
              {/*  <option>Junior Level</option>*/}
              {/*  <option>Mid Level</option>*/}
              {/*  <option>Senior Level</option>*/}
              {/*</select>*/}
              {/* </div> */}
              <div className="selectHolder mx-3">
                <select
                  className="form-select"
                  value={year}
                  onChange={savingsYearHandler}
                  style={{ minWidth: "124px" }}
                >
                  {previousTenYears?.map((year, index) => (
                    <option key={index} value={year}>
                      {year === new Date().getFullYear()
                        ? "This Year"
                        : `${year}`}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          {!loading && (
            <>
              <div className="savingChart pb-3">
                <ResponsiveContainer
                  width={"100%"}
                  height={450}
                  min-width={300}
                >
                  <BarChart
                    width={500}
                    height={300}
                    data={estimatedSavings}
                    margin={{
                      top: 20,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                    onClick={handleBar}
                  >
                    <XAxis tickLine={false} axisLine={false} dataKey="name" />
                    {/*<Tooltip />*/}
                    <Bar
                      dataKey="saving"
                      minPointSize={5}
                      radius={[5, 5, 0, 0]}
                      label={renderCustomBarLabel}
                    >
                      {estimatedSavings?.map((saving, index) => (
                        <Bar
                          key={index}
                          dataKey="saving"
                          fill={getBarColor(saving)}
                        />
                      ))}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
