import React from "react";

export const TopInfoCard = ({
  icon = "ti-key",
  iconClasses = "bg-label-primary",
  count = 0,
  subTitle = "--",
}) => {
  return (
    <div className="col-sm-6 col-md-4">
      <div className="card h-100">
        <div className="card-body">
          <span className={`badge rounded p-2 ${iconClasses}`}>
            <i className={`ti ti-sm ${icon}`}></i>
          </span>
          <div className="content-left">
            <div className="d-flex align-items-center my-2">
              <h4 className="mb-0 me-2">{count}</h4>
            </div>
            <p className="mb-0">{subTitle}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
