import React, { useEffect, useState } from "react";
import { Users } from "../Components/Dashboard/users";
import { Offers } from "../Components/Dashboard/offers";
import { Redemptions } from "../Components/Dashboard/redemptions";
import { Savings } from "../Components/Dashboard/savings";

import { userAnalytics as UserAnalytics } from "../Components/Dashboard/userAnalytics";
import { UserJourney } from "../Components/Dashboard/userJourney";
import NoEmploy from "../Components/Dashboard/noEmploy";
import { EmployeeList } from "../Components/Employee/employeeList";
import { useSelector } from "react-redux";
import { ApiWrapper } from "../Api/apiWrapper";
import { useDispatch } from "react-redux";
import { setConfig } from "../features/configSlice";

export const Dashboard = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const localData = JSON.parse(localStorage.getItem("data"));

  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState({});
  const [offersData, setOffersData] = useState({});
  const [redemptionData, setRedemptionData] = useState({});
  const [savingData, setSavingData] = useState({});
  const [employeeData, setEmployeeData] = useState({});
  const [merchantData, setMerchantData] = useState({});
  const [categoryData, setCategoryData] = useState({});
  const [isEmployee, setIsEmployee] = useState(false);

  useEffect(() => {
    const requestData = JSON.stringify({
      company_code: user.company_code,
      session_token: localData.session_token,
      locale: localStorage.getItem("i18nextLng"),
    });

    const apiEndpoints = [
      "/dashboard/v1/getdashboardconfigs",
      "/employee/v1/getemployeeconfigs",
      "/merchant/v1/getmerchantconfigs",
      "/dashboard/v1/getuserscount",
      "/dashboard/v1/getofferscount",
      "/dashboard/v1/getredemptionscount",
      "/dashboard/v1/getsavingscount",
      "/dashboard/v1/getallemployees",
      "/dashboard/v1/gettopmerchants",
      "/dashboard/v1/getcategories",
    ];

    const apiPromises = apiEndpoints?.map((endpoint) =>
      ApiWrapper({
        path: endpoint,
        method: "POST",
        data: requestData,
      })
    );

    Promise.all(apiPromises)
      .then((data) => {
        setIsEmployee(false);
        dispatch(
          setConfig({
            dashboard: data[0]?.data || {},
            employee: data[1]?.data || {},
            merchant: data[2]?.data || {},
          })
        );
        setUserData(data[3].data || {});
        setOffersData(data[4].data || {});
        setRedemptionData(data[5].data || {});
        setSavingData(data[6].data || {});
        setEmployeeData(data[7].data || {});
        setMerchantData(data[8].data || {});
        setCategoryData(data[9].data || {});
        setLoading(false);
        if (data[7]?.data?.length > 0) {
          setIsEmployee(true);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  return (
    <>
      {!loading ? (
        <div className="layout-page">
          <div className="content-wrapper">
            <div className="container-fluid flex-grow-1 container-p-y">
              <div className="row g-4 mb-4">
                <Users userData={userData} />
                <Offers offersData={offersData} />
                <Redemptions redemptionData={redemptionData} />
                <Savings savingData={savingData} />
              </div>
              {isEmployee && (
                <UserAnalytics
                  merchantData={merchantData}
                  categoryData={categoryData}
                />
              )}
              {isEmployee ? (
                <EmployeeList
                  requestKeyButton={false}
                  employeeData={employeeData}
                  setEmployeeData={setEmployeeData}
                />
              ) : (
                <NoEmploy setEmployeeData={setEmployeeData} />
              )}
            </div>
          </div>
          {localData.is_first_login ? <UserJourney /> : ""}
        </div>
      ) : (
        <div className="pageLoader d-flex align-items-center justify-content-center">
          <div className="spinner-border spinner-border-lg text-primary"></div>
        </div>
      )}
    </>
  );
};
