import React from "react";
import { useTranslation } from "react-i18next";

export const TopMerchants = ({ merchantData }) => {
  const { t } = useTranslation();

  return (
    <div className="col-md-6 mb-4">
      <div className="card h-100">
        <div className="card-header d-flex justify-content-between">
          <div className="card-title mb-0">
            <h5
              className="mb-0"
              data-bs-toggle="modal"
              data-bs-target="#journeyModal"
            >
              {t("top_merchants.top_merchants_title")}
            </h5>
          </div>
        </div>
        <div className="card-body pb-0">
          <ul className="p-0 m-0">
            {merchantData?.merchants?.map((merchant, index) => (
              <li className="mb-3 pb-1 d-flex" key={index}>
                <div className="d-flex w-50 align-items-center me-3">
                  <img
                    src={merchant.logo}
                    alt="logo"
                    className="me-3"
                    width="40"
                  />
                  <div>
                    <h6 className="mb-0">{merchant.name}</h6>
                  </div>
                </div>
                <div className="d-flex flex-grow-1 align-items-center">
                  <div
                    className="progress w-100 me-3"
                    style={{ height: "8px" }}
                  >
                    <div
                      className="progress-bar bg-danger"
                      role="progressbar"
                      style={{ width: `${merchant.redemption_percentage}%` }}
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                  <span className="text-muted">
                    {merchant.redemption_percentage ?? 0}%
                  </span>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
