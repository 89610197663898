import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import {
  INVALID_CODES,
  SUCCESS_CODE,
  SUCCESS_CODES,
} from "../../Api/constants";
import { ApiWrapper } from "../../Api/apiWrapper";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { KeyComponent } from "../Dashboard/Keys/keyComponent";
import { EditEmployee } from "./employeeProfile/editEmployee";
import { Alert } from "react-bootstrap";
import { RemoveAccess } from "./employeeProfile/removeAccess";
import { EmptyListing } from "../Shared/EmptyListing";

export const EmployeeList = ({
  requestKeyButton,
  employeeData,
  setEmployeeData,
}) => {
  const user = useSelector((state) => state.user.user);
  const config = useSelector((state) => state.config?.config?.employee || {});
  const localData = JSON.parse(localStorage.getItem("data"));

  const [searchText, setSearchText] = useState("");
  const [status, setStatus] = useState("");
  const [tier, setTier] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showAddEmployeeModal, setShowAddEmployeeModal] = useState(false);
  const [requestKeyComponent, setRequestKeyComponent] = useState(false);
  const [confirmModalLoader, setConfirmModalLoader] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showDeleteEmployeeModal, setShowDeleteEmployeeModal] = useState(false);
  const [toggledClearRows, setToggleClearRows] = useState(false);

  const handleRowSelected = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
  };

  const columns = [
    {
      className: "column-id",
      name: "ID",
      selector: (row) => row.id ?? "",
      cell: (row) => <div className="id-column">{`#${row.id ?? ""}`}</div>,
      sortable: true,
      width: "120px",
    },
    {
      name: "EMPLOYEE",
      selector: (row) => `${row.first_name} ${row.last_name}`,
      cell: (row) => (
        <div className="d-flex align-items-center">
          <Link
            to={`/employee/detail/${row.id}`}
            className="userImg d-flex align-items-center justify-content-center me-2"
            style={{
              width: "38px",
              height: "38px",
              borderRadius: "50%",
              overflow: "hidden",
              background: "rgba(115,103,240,0.16)",
              color: "#1EB0B3",
            }}
          >
            {!row.profile_image ? (
              <p className="m-0">
                {row.first_name.charAt(0).toUpperCase()}
                {row.last_name.charAt(0).toUpperCase()}
              </p>
            ) : (
              <img
                src={row.profile_image}
                alt=""
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            )}
          </Link>
          <div className="title">
            {row.first_name} {row.last_name}{" "}
            <span className="sub-title">{row.email}</span>
          </div>
        </div>
      ),
      sortable: true,
      minWidth: "35%",
    },
    {
      name: "MOBILE",
      selector: (row) => row.mobile_no ?? "",
      sortable: true,
    },
    {
      name: "CREATED ON",
      selector: (row) => row.created_at ?? "",
      sortable: true,
    },
    {
      name: "TIER",
      selector: (row) => row.tier_name ?? "",
      cell: (row) => (
        <span className="badge bg-label-dark mt-1">{row.tier_name ?? ""}</span>
      ),
      sortable: true,
    },
    {
      name: "STATUS",
      selector: (row) => row.status ?? "",
      cell: (row) => {
        const getStatusClassName = (status) => {
          if (status === "Active") {
            return "badge bg-label-primary mt-1";
          } else if (status === "Key unassigned") {
            return "badge bg-label-warning mt-1";
          } else {
            return "badge bg-label-secondary mt-1";
          }
        };
        const className = getStatusClassName(row.status);
        return <span className={className}>{row.status ?? ""}</span>;
      },
      sortable: true,
    },
  ];

  const filteredData = employeeData?.filter(
    (item) =>
      item.first_name.toLowerCase().includes(searchText.toLowerCase()) ||
      item.status.toLowerCase().includes(searchText.toLowerCase()) ||
      item.created_at.toLowerCase().includes(searchText.toLowerCase())
  );

  const toggleRequestKeyComponent = () => {
    setRequestKeyComponent(!requestKeyComponent);
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
    fetchEmployeeList(event.target.value, tier);
  };

  const handleTierChange = (event) => {
    setTier(event.target.value);
    fetchEmployeeList(status, event.target.value);
  };

  const fetchEmployeeList = async (status = "", tier = null) => {
    const localData = JSON.parse(localStorage.getItem("data"));
    const requestData = JSON.stringify({
      company_code: user.company_code,
      session_token: localData.session_token,
      status: status == -1 ? "" : status,
      user_group: tier == -1 ? null : tier,
      locale: localStorage.getItem("i18nextLng"),
    });
    const data = await ApiWrapper({
      path: "/dashboard/v1/getallemployees",
      method: "POST",
      data: requestData,
    });
    if (data.status_code === SUCCESS_CODE) {
      const jsonData = JSON.stringify(data.data);
      const parsedData = JSON.parse(jsonData);
      setEmployeeData(parsedData);

      // setSelectedRows([]);
      // setToggleClearRows(!toggledClearRows)
      // TODO: Commented below line as it was suddenly making field empty after filter aplied
      // setStatus("");
      setTier(null);
    } else {
      console.log("something went wrong");
    }
  };

  const handleBulkEmployeeDelete = async () => {
    const employeeIds = selectedRows?.map((emp) => emp.id);

    try {
      setConfirmModalLoader(true);
      const requestData = JSON.stringify({
        company_code: user.company_code,
        session_token: localData.session_token,
        locale: localStorage.getItem("i18nextLng"),
        employee_ids: employeeIds,
      });
      const data = await ApiWrapper({
        path: "/employee/v1/deleteemployee",
        method: "POST",
        data: requestData,
      });
      if (INVALID_CODES.includes(data.status_code)) {
        setSuccessMessage("");
        setErrorMessage(data.message || "Something went wrong!");
      } else if (SUCCESS_CODES.includes(data.status_code)) {
        fetchEmployeeList();
        setErrorMessage("");
        setSuccessMessage(data.message || "Users will be deleted in 16 days");
        setSelectedRows([]);
        setToggleClearRows(!toggledClearRows);
        setStatus("");
        setTier(null);
      }
    } catch (error) {
      console.log({ error });
    } finally {
      setConfirmModalLoader(false);
      setShowDeleteEmployeeModal(false);
      setSelectedRows([]);
    }
  };

  const EmptyListingActions = () => {
    return (
      <div className="col-md-12 col-12">
        <button
          type="button"
          className=" btn btn-outline-primary waves-effect w-48 me-2"
          onClick={() => setShowAddEmployeeModal(true)}
        >
          Add Employee
        </button>
      </div>
    );
  };

  return (
    <>
      <div className="row g-4 mb-4">
        <div className="col-12">
          <div className="card">
            {(errorMessage || successMessage) && (
              <Alert
                variant={successMessage ? "success" : "danger"}
                dismissible
                onClose={() => {
                  setErrorMessage("");
                  setSuccessMessage("");
                }}
                className="position-absolute w-100"
                style={{ top: "0", left: "0", right: "o", zIndex: "9999" }}
              >
                {errorMessage || successMessage}
              </Alert>
            )}
            <div className="card-header">
              <h5 className="card-title mb-3">Employee List</h5>
              <div className="dataTable-head d-flex align-items-center">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Search..."
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  style={{ width: "293px" }}
                />
                <div className="card-action-element d-flex ms-auto py-0">
                  {selectedRows?.length ? (
                    <>
                      <button
                        tabIndex="0"
                        aria-controls="DataTables_Table_0"
                        type="button"
                        className="dt-button add-new btn btn-danger ms-3"
                        onClick={() => setShowDeleteEmployeeModal(true)}
                      >
                        <span>
                          <i className="ti me-0 me-sm-1 ti-xs"></i>
                          <span className="d-none d-sm-inline-block">
                            Delete Employee
                          </span>
                        </span>
                      </button>

                      {showDeleteEmployeeModal && (
                        <RemoveAccess
                          title={"Delete Users"}
                          message={
                            "Are you sure you want to delete the selected users?"
                          }
                          handleClose={() => setShowDeleteEmployeeModal(false)}
                          handleOkBtn={handleBulkEmployeeDelete}
                          loading={confirmModalLoader}
                        />
                      )}
                    </>
                  ) : (
                    <>
                      <div
                        className="selectHolder"
                        style={{ maxWidth: "136px" }}
                      >
                        <select
                          className="form-select"
                          value={tier}
                          onChange={handleTierChange}
                        >
                          <option value={-1}>Select Tier</option>
                          {config?.tiers?.map(
                            (tier, index) =>
                              tier.tier_keys > 0 && (
                                <option
                                  key={index}
                                  value={tier.tier_user_group}
                                >
                                  {tier.tier_title}
                                </option>
                              )
                          )}
                        </select>
                      </div>
                      <div
                        className="selectHolder ms-3"
                        style={{ maxWidth: "165px" }}
                      >
                        <select
                          className="form-select"
                          value={status}
                          onChange={handleStatusChange}
                        >
                          <option value={-1}>Select Status</option>
                          {config?.employee_status?.map((status, index) => (
                            <option key={index} value={status}>
                              {status}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div>
                        <button
                          tabIndex="0"
                          aria-controls="DataTables_Table_0"
                          type="button"
                          className="dt-button add-new btn btn-outline-primary ms-3"
                          onClick={() => setShowAddEmployeeModal(true)}
                        >
                          <span>
                            <i className="ti ti-user-plus me-0 me-sm-1 ti-xs"></i>
                            <span className="d-none d-sm-inline-block">
                              Add Employee
                            </span>
                          </span>
                        </button>
                        {requestKeyButton ? (
                          <button
                            tabIndex="0"
                            aria-controls="DataTables_Table_0"
                            type="button"
                            className="dt-button add-new btn btn-outline-primary ms-3"
                            onClick={toggleRequestKeyComponent}
                          >
                            <span>
                              <i className="ti ti-key me-0 me-sm-1 ti-xs"></i>
                              <span className="d-none d-sm-inline-block">
                                Request Keys
                              </span>
                            </span>
                          </button>
                        ) : (
                          <></>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            {employeeData?.length ? (
              <div className="tableHolder">
                <DataTable
                  className="tableHolderInner"
                  columns={columns}
                  data={filteredData}
                  selectableRows
                  selectableRowDisabled={(row) => row.in_delete_process}
                  onSelectedRowsChange={handleRowSelected}
                  selectableRowsVisibleOnly
                  pagination={true}
                  clearSelectedRows={toggledClearRows}
                  // noDataComponent={<NoDataComponent />}
                />
              </div>
            ) : (
              <div className="row" style={{ height: "calc(100vh - 270px)" }}>
                <div className="col-12">
                  <EmptyListing
                    title={"There is no employee"}
                    content={
                      "Get started by adding employees. all of your employees will be listed here"
                    }
                    icon={"ti-users"}
                    actions={EmptyListingActions}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        {showAddEmployeeModal && (
          <EditEmployee
            title="Add Employee"
            oKBtnText="Save"
            onClose={() => setShowAddEmployeeModal(false)}
            callback={fetchEmployeeList}
            endpoint={"add_employee"}
          />
        )}
        {requestKeyComponent && (
          <KeyComponent
            toggleRequestKeyComponent={toggleRequestKeyComponent}
            config={config}
          />
        )}
      </div>
    </>
  );
};
