import React, { useState } from "react";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";

export const UserJourney = () => {
  const localData = JSON.parse(localStorage.getItem("data"));
  const [isVisible, setIsVisible] = useState(true);

  const handleHideClick = () => {
    setIsVisible(false);
    localData.is_first_login = false;
    localStorage.setItem("data", JSON.stringify(localData));
  };

  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [sliderRef, instanceRef] = useKeenSlider({
    initial: 0,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
  });
  return (
    <>
      {isVisible && (
        <div className="defaultModal">
          <span className="backDrop" onClick={handleHideClick}></span>
          <div className="modalView">
            <div className="modalBlock py-5 px-4">
              <button className="btn-close" onClick={handleHideClick}></button>
              <div className="navigation-wrapper">
                <div ref={sliderRef} className="keen-slider">
                  <div className="keen-slider__slide number-slide1">
                    <div className="text-center pb-5">
                      <span className="badge bg-label-primary bg-primary mb-3">
                        Get started
                      </span>
                      <h3 className="mb-2 fw-bold">
                        Great to have you onboard
                      </h3>
                      <p className="text-dark mb-5">
                        Feel free to explore, or get a head start below
                      </p>
                      <div className="slideContent_center mx-auto">
                        <span className="icon mb-3 d-inline-block">
                          <svg
                            width="65"
                            height="64"
                            viewBox="0 0 65 64"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              opacity="0.2"
                              d="M10.6201 44V18C10.6201 16.9391 11.0415 15.9217 11.7917 15.1716C12.5418 14.4214 13.5593 14 14.6201 14H50.6201C51.681 14 52.6984 14.4214 53.4485 15.1716C54.1987 15.9217 54.6201 16.9391 54.6201 18V44H10.6201Z"
                              fill="#80C100"
                            />
                            <path
                              d="M10.6201 44V18C10.6201 16.9391 11.0415 15.9217 11.7917 15.1716C12.5418 14.4214 13.5593 14 14.6201 14H50.6201C51.681 14 52.6984 14.4214 53.4485 15.1716C54.1987 15.9217 54.6201 16.9391 54.6201 18V44M36.6201 22H28.6201M6.62012 44H58.6201V48C58.6201 49.0609 58.1987 50.0783 57.4485 50.8284C56.6984 51.5786 55.681 52 54.6201 52H10.6201C9.55925 52 8.54184 51.5786 7.79169 50.8284C7.04154 50.0783 6.62012 49.0609 6.62012 48V44Z"
                              stroke="#80C100"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                        <h5 className="mb-3">View Insights</h5>
                        <p className="text-dark">
                          Start getting insights and know what’s going under the
                          hood.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="keen-slider__slide number-slide2">
                    <div className="text-center pb-5">
                      <span className="badge bg-label-primary bg-primary mb-3">
                        Get started
                      </span>
                      <h3 className="mb-2 fw-bold">
                        Great to have you onboard
                      </h3>
                      <p className="text-dark mb-5">
                        Feel free to explore, or get a head start below
                      </p>
                      <div className="slideContent_center mx-auto">
                        <span className="icon mb-3 d-inline-block">
                          <svg
                            width="65"
                            height="64"
                            viewBox="0 0 65 64"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              opacity="0.2"
                              d="M10.6201 44V18C10.6201 16.9391 11.0415 15.9217 11.7917 15.1716C12.5418 14.4214 13.5593 14 14.6201 14H50.6201C51.681 14 52.6984 14.4214 53.4485 15.1716C54.1987 15.9217 54.6201 16.9391 54.6201 18V44H10.6201Z"
                              fill="#80C100"
                            />
                            <path
                              d="M10.6201 44V18C10.6201 16.9391 11.0415 15.9217 11.7917 15.1716C12.5418 14.4214 13.5593 14 14.6201 14H50.6201C51.681 14 52.6984 14.4214 53.4485 15.1716C54.1987 15.9217 54.6201 16.9391 54.6201 18V44M36.6201 22H28.6201M6.62012 44H58.6201V48C58.6201 49.0609 58.1987 50.0783 57.4485 50.8284C56.6984 51.5786 55.681 52 54.6201 52H10.6201C9.55925 52 8.54184 51.5786 7.79169 50.8284C7.04154 50.0783 6.62012 49.0609 6.62012 48V44Z"
                              stroke="#80C100"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                        <h5 className="mb-3">View Insights</h5>
                        <p className="text-dark">
                          Start getting insights and know what’s going under the
                          hood.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="keen-slider__slide number-slide3">
                    <div className="text-center pb-5">
                      <span className="badge bg-label-primary bg-primary mb-3">
                        Get started
                      </span>
                      <h3 className="mb-2 fw-bold">
                        Great to have you onboard
                      </h3>
                      <p className="text-dark mb-5">
                        Feel free to explore, or get a head start below
                      </p>
                      <div className="slideContent_center mx-auto">
                        <span className="icon mb-3 d-inline-block">
                          <svg
                            width="65"
                            height="64"
                            viewBox="0 0 65 64"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              opacity="0.2"
                              d="M10.6201 44V18C10.6201 16.9391 11.0415 15.9217 11.7917 15.1716C12.5418 14.4214 13.5593 14 14.6201 14H50.6201C51.681 14 52.6984 14.4214 53.4485 15.1716C54.1987 15.9217 54.6201 16.9391 54.6201 18V44H10.6201Z"
                              fill="#80C100"
                            />
                            <path
                              d="M10.6201 44V18C10.6201 16.9391 11.0415 15.9217 11.7917 15.1716C12.5418 14.4214 13.5593 14 14.6201 14H50.6201C51.681 14 52.6984 14.4214 53.4485 15.1716C54.1987 15.9217 54.6201 16.9391 54.6201 18V44M36.6201 22H28.6201M6.62012 44H58.6201V48C58.6201 49.0609 58.1987 50.0783 57.4485 50.8284C56.6984 51.5786 55.681 52 54.6201 52H10.6201C9.55925 52 8.54184 51.5786 7.79169 50.8284C7.04154 50.0783 6.62012 49.0609 6.62012 48V44Z"
                              stroke="#80C100"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                        <h5 className="mb-3">View Insights</h5>
                        <p className="text-dark">
                          Start getting insights and know what’s going under the
                          hood.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="keen-slider__slide number-slide4">
                    <div className="text-center pb-5">
                      <span className="badge bg-label-primary bg-primary mb-3">
                        Get started
                      </span>
                      <h3 className="mb-2 fw-bold">
                        Great to have you onboard
                      </h3>
                      <p className="text-dark mb-5">
                        Feel free to explore, or get a head start below
                      </p>
                      <div className="slideContent_center mx-auto">
                        <span className="icon mb-3 d-inline-block">
                          <svg
                            width="65"
                            height="64"
                            viewBox="0 0 65 64"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              opacity="0.2"
                              d="M10.6201 44V18C10.6201 16.9391 11.0415 15.9217 11.7917 15.1716C12.5418 14.4214 13.5593 14 14.6201 14H50.6201C51.681 14 52.6984 14.4214 53.4485 15.1716C54.1987 15.9217 54.6201 16.9391 54.6201 18V44H10.6201Z"
                              fill="#80C100"
                            />
                            <path
                              d="M10.6201 44V18C10.6201 16.9391 11.0415 15.9217 11.7917 15.1716C12.5418 14.4214 13.5593 14 14.6201 14H50.6201C51.681 14 52.6984 14.4214 53.4485 15.1716C54.1987 15.9217 54.6201 16.9391 54.6201 18V44M36.6201 22H28.6201M6.62012 44H58.6201V48C58.6201 49.0609 58.1987 50.0783 57.4485 50.8284C56.6984 51.5786 55.681 52 54.6201 52H10.6201C9.55925 52 8.54184 51.5786 7.79169 50.8284C7.04154 50.0783 6.62012 49.0609 6.62012 48V44Z"
                              stroke="#80C100"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                        <h5 className="mb-3">View Insights</h5>
                        <p className="text-dark">
                          Start getting insights and know what’s going under the
                          hood.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {loaded && instanceRef.current && (
                  <>
                    <Arrow
                      left
                      onClick={(e) =>
                        e.stopPropagation() || instanceRef.current?.prev()
                      }
                      disabled={currentSlide === 0}
                    />

                    <Arrow
                      onClick={(e) =>
                        e.stopPropagation() || instanceRef.current?.next()
                      }
                      disabled={
                        currentSlide ===
                        instanceRef?.current?.track?.details?.slides?.length - 1
                      }
                    />
                  </>
                )}
              </div>
              {loaded && instanceRef?.current && (
                <div className="dots slider-dots">
                  {[
                    ...Array(
                      instanceRef?.current?.track?.details?.slides?.length
                    )?.keys(),
                  ]?.map((idx) => {
                    return (
                      <button
                        key={idx}
                        onClick={() => {
                          instanceRef.current?.moveToIdx(idx);
                        }}
                        className={
                          "dot" + (currentSlide === idx ? " active" : "")
                        }
                      ></button>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

function Arrow(props) {
  const disabeld = props.disabled ? " arrow--disabled" : "";
  return (
    <div
      onClick={props.onClick}
      className={`arrow ${
        props.left ? "arrow--left" : "arrow--right"
      } ${disabeld}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      {props.left && <i className="menu-icon tf-icons ti ti-chevron-left"></i>}
      {!props.left && (
        <i className="menu-icon tf-icons ti ti-chevron-right"></i>
      )}
    </div>
  );
}
