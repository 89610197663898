import React, { useState } from "react";
import { Link } from "react-router-dom";
import { VerifyEmail } from "./verifyEmail";
import { useTranslation } from "react-i18next";
import { ApiWrapper } from "../../Api/apiWrapper";
import { SUCCESS_CODE } from "../../Api/constants";

export const ForgetPassword = () => {
  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [linkSent, setLinkSend] = useState(false);
  const [activationLinkMessage, setActivationLinkMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const emailChangeHandler = (event) => {
    setEmail(event.target.value);
  };
  const onForgetPasswordHandler = (event) => {
    let errors = {};
    event.preventDefault();
    if (ValidateForgetPasswordForm()) {
      setLoading(true);
      const requestData = JSON.stringify({
        email: email,
        locale: localStorage.getItem("i18nextLng"),
      });
      (async () => {
        const data = await ApiWrapper({
          path: "/auth/v1/forgetpassword",
          method: "POST",
          data: requestData,
        });
        if (data.status_code === SUCCESS_CODE) {
          setActivationLinkMessage(data.message);
          setLinkSend(true);
          setLoading(false);
        } else {
          setLoading(false);
          console.log("Something wrong");
          errors.email = data.message;
          setFormErrors(errors);
        }
      })();
    }
  };

  const ValidateForgetPasswordForm = () => {
    let errors = {};
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!email.trim()) {
      errors.email = "Email field is required";
    }
    if (email && !emailPattern.test(email)) {
      errors.email = "Please enter a  valid email";
    }
    // Set formErrors state
    setFormErrors(errors);
    // Return true if there are no errors
    return Object.keys(errors)?.length === 0;
  };
  return (
    <>
      {!linkSent ? (
        <div className="container-xxl">
          <div className="authentication-wrapper authentication-basic container-p-y">
            <div className="authentication-inner py-4">
              <div className="card">
                <div className="card-body">
                  <div className="app-brand justify-content-center mb-4 mt-2">
                    <span className="app-brand-link">
                      <img
                        src="/images/app-logo-vertical.png"
                        alt="Reward Logo"
                        className="img-fluid"
                      />
                    </span>
                  </div>
                  <h4 className="mb-1 pt-2">
                    {t("forgot_password_screen.forgot_password")} 🔒
                  </h4>
                  <p className="mb-4">
                    {t("forgot_password_screen.instruction")}
                  </p>
                  <form className="mb-3" onSubmit={onForgetPasswordHandler}>
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label">
                        {t("forgot_password_screen.form_fields.email")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="email"
                        name="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={emailChangeHandler}
                      />
                      {formErrors.email && (
                        <span className="error-color">{formErrors.email}</span>
                      )}
                    </div>
                    <button className="btn btn-primary d-grid w-100">
                      {t("forgot_password_screen.form_fields.reset_link")}
                    </button>
                  </form>
                  <div className="text-center">
                    <Link
                      to="/login"
                      className="d-flex align-items-center justify-content-center auth-link"
                    >
                      <i className="ti ti-chevron-left scaleX-n1-rtl"></i>
                      {t("buttons.back_to_sign_in")}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <VerifyEmail
          activationLinkMessage={activationLinkMessage}
          setLinkSend={setLinkSend}
        />
      )}
      {loading ? (
        <div className="pageLoader d-flex align-items-center justify-content-center">
          <div className="spinner-border spinner-border-lg text-primary"></div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
