import React, { useEffect, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useSelector } from "react-redux";
import { ApiWrapper } from "../../Api/apiWrapper";
import {
  EMPLOYEE_UPDATED,
  INVALID_CODES,
  REQUEST_FAILED,
  SUCCESS_CODE,
  SUCCESS_CODES,
} from "../../Api/constants";
import { ResponseModal } from "../Employee/responseModal";
import Select from "react-select";
import { Alert } from "react-bootstrap";
import { languages } from "../../Api/constants";

export const EditProfile = ({ onClose, userData, setUserData }) => {
  const user = useSelector((state) => state.user.user);
  const localData = JSON.parse(localStorage.getItem("data"));

  const [data, setData] = useState({});
  const [countryList, setCountryList] = useState([]);
  const [alertMessage, setAlertMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showResponseModal, setShowResponseModal] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (userData) {
      setData(userData);
    }
  }, [userData]);

  useEffect(() => {
    fetchResidenceCountry();
  }, []);

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];

    const fileSize = Math.round(file.size / 1024);
    if (fileSize > 800) {
      setAlertMessage("Size Exceeded! Please Select image upto 800kb");
      setTimeout(() => setAlertMessage(""), 3000);
      return;
    }

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64 = e.target.result;
        updateImageHandler(base64);
      };
      reader.readAsDataURL(file);
    }
  };

  const onChangeInputData = (key, value) => {
    if (key === "employee_id" || key === "mobile_no") {
      if (/^\+?\d*$/.test(value)) {
        setData({
          ...data,
          [key]: value,
        });
      }
    } else {
      setData({
        ...data,
        [key]: value,
      });
    }
  };

  const updateImageHandler = async (fileIntoBase64) => {
    setImageLoading(true);
    const requestData = JSON.stringify({
      company_code: user.company_code,
      session_token: localData.session_token,
      locale: localStorage.getItem("i18nextLng"),
      profile_image: fileIntoBase64,
    });

    const data = await ApiWrapper({
      path: "/auth/v1/uploadprofileimage",
      method: "POST",
      data: requestData,
    });
    if (data.status_code === SUCCESS_CODE) {
      setUserData({ ...userData, profile_img: data?.data?.image_url });
      setImageLoading(false);
    } else {
      console.log("something went wrong");
    }
  };

  const fetchResidenceCountry = async () => {
    const requestData = JSON.stringify({
      company_code: user.company_code,
      session_token: localData.session_token,
      locale: localStorage.getItem("i18nextLng"),
    });
    const data = await ApiWrapper({
      path: "/auth/v1/getcountries",
      method: "POST",
      data: requestData,
    });
    if (SUCCESS_CODES.includes(data.status_code)) {
      const updatedArray = data?.data?.map((country) => {
        const { id: valueToRemove, ...rest } = country;
        return rest;
      });
      const countryArray = updatedArray?.map((country) => {
        const { name, shortname, ...rest } = country;
        return { label: name, value: shortname, ...rest };
      });
      setCountryList(countryArray);
    } else {
      console.log("something went wrong");
    }
  };

  const UpdateProfileHandler = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const requestData = JSON.stringify({
        company_code: user.company_code,
        session_token: localData.session_token,
        first_name: data.first_name,
        last_name: data.last_name,
        mobile_no: data.mobile_no,
        email: data.email,
        language: data.language,
        country: data.country,
        employee_id: data.employee_id,
        locale: localStorage.getItem("i18nextLng"),
      });
      const res = await ApiWrapper({
        path: "/auth/v1/updateprofile",
        method: "POST",
        data: requestData,
      });

      if (INVALID_CODES.includes(res.status_code) || res.errorMessage) {
        setErrorMessage(
          res.errorMessage || res.message || "Something went wrong!"
        );
      } else if (SUCCESS_CODES.includes(res.status_code)) {
        fetchUserDetail();
      }
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false);
      setShowResponseModal(true);
    }
  };

  const fetchUserDetail = async () => {
    const requestData = JSON.stringify({
      company_code: user.company_code,
      session_token: localData.session_token,
      user_id: user.id,
      locale: localStorage.getItem("i18nextLng"),
    });
    const data = await ApiWrapper({
      path: "/auth/v1/userdetails",
      method: "POST",
      data: requestData,
    });
    if (data.status_code === SUCCESS_CODE) {
      setUserData(data.data);
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();
    onClose();
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: "1px solid #dbdade", // Border color
      borderRadius: "6px", // Border radius
      cursor: "pointer",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#80C100" : "white", // Background color on hover/focus
      color: state.isFocused ? "white" : "black", // Text color on hover/focus
      boxShadow: state.isFocused ? "none" : "none", // Add box shadow on focus
      borderRadius: "6px",
    }),
    menu: (provided) => ({
      ...provided,
      boxShadow: "0 0 0 1px rgba(0,0,0,0.1)", // Add box shadow on focus
      border: "0", // Border color for the menu
      borderRadius: "6px", // Border radius for the menu
      padding: "10px",
    }),
  };

  return (
    <>
      <Offcanvas show={true} onHide={onClose} placement="end">
        {alertMessage && (
          <Alert
            variant="warning"
            onClose={() => setAlertMessage("")}
            dismissible
            className="position-absolute"
            style={{ zIndex: "99" }}
          >
            {alertMessage}
          </Alert>
        )}
        {loading ? (
          <div className="pageLoader d-flex align-items-center justify-content-center position-absolute">
            <div className="spinner-border spinner-border-lg text-primary"></div>
          </div>
        ) : (
          ""
        )}
        {showResponseModal ? (
          <ResponseModal
            toggleMainComponent={onClose}
            operation={errorMessage ? REQUEST_FAILED : EMPLOYEE_UPDATED}
            heading={errorMessage ? "Error" : "Information Updated"}
            message={
              errorMessage ||
              "Employee profile information has been successfully Updated"
            }
          />
        ) : (
          <div className="d-flex flex-column h-100">
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Edit Info</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div className="user-avatar-section mb-3">
                <div className="d-flex align-items-start">
                  <div className="userProfileImg position-relative me-4">
                    {imageLoading ? (
                      <div className="pageLoader d-flex align-items-center justify-content-center position-absolute">
                        <div className="spinner-border spinner-border-md text-primary"></div>
                      </div>
                    ) : (
                      ""
                    )}
                    <img
                      src={
                        data.profile_img || "/images/default-profile-img.png"
                      }
                      alt="User Profile image"
                      width="100"
                      height="100"
                      className="img-fluid"
                    />
                  </div>
                  <div className="user-info">
                    <div className="custom-file-upload mb-3">
                      <button className="btn btn-primary">
                        Upload new photo
                      </button>
                      <input
                        type="file"
                        id="fileInput"
                        className="file-btn"
                        onChange={handleFileInputChange}
                        accept="image/x-png,image/gif,image/jpeg"
                      />
                    </div>
                    <p className="text-muted mb-0">
                      Allowed JPG, GIF or PNG. Max size of 800K
                    </p>
                  </div>
                </div>
              </div>
              <form onSubmit={UpdateProfileHandler}>
                <div>
                  <div className="mb-3">
                    <label htmlFor="first-name" className="form-label">
                      First Name
                    </label>
                    <input
                      type="text"
                      name="first_name"
                      className="form-control"
                      id="first-name"
                      value={data.first_name}
                      onChange={(event) =>
                        onChangeInputData("first_name", event.target.value)
                      }
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="last-name" className="form-label">
                      Last Name
                    </label>
                    <input
                      type="text"
                      name="last_name"
                      className="form-control"
                      id="last-name"
                      value={data.last_name}
                      onChange={(event) =>
                        onChangeInputData("last_name", event.target.value)
                      }
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="userID" className="form-label">
                      ID
                    </label>
                    <input
                      type="text"
                      name="employeeId"
                      className="form-control"
                      id="userID"
                      value={data.employee_id}
                      onChange={(event) =>
                        onChangeInputData("employee_id", event.target.value)
                      }
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                      Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      id="email"
                      value={data.email}
                      disabled
                      // onChange={(event) =>
                      //   onChangeInputData("email", event.target.value)
                      // }
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="phone" className="form-label">
                      Phone
                    </label>
                    <input
                      type="tel"
                      name="phone"
                      className="form-control"
                      id="phone"
                      value={data.mobile_no}
                      onChange={(event) =>
                        onChangeInputData("mobile_no", event.target.value)
                      }
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="languageSelect" className="form-label">
                      Language
                    </label>
                    <Select
                      styles={customStyles}
                      menuPlacement="top"
                      value={languages?.filter(
                        (lang) => data.language == lang.value
                      )}
                      options={languages}
                      onChange={(val) =>
                        onChangeInputData("language", val.value)
                      }
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="languageSelect" className="form-label">
                      Nationality
                    </label>
                    <Select
                      styles={customStyles}
                      menuPlacement="top"
                      value={countryList?.filter(
                        (country) => data.country == country.value
                      )}
                      options={countryList}
                      onChange={(val) =>
                        onChangeInputData("country", val.value)
                      }
                      required
                    />
                  </div>
                </div>
                <div className="bottom-btnWrap d-flex justify-content-between gap-3 position-absolute bottom-0 start-0 end-0 pb-4 ps-4 pe-4">
                  <button className="btn btn-primary d-grid w-100">Save</button>
                  <button
                    className="btn-label-secondary d-grid w-100 btn"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </Offcanvas.Body>
          </div>
        )}
      </Offcanvas>
    </>
  );
};
