import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export const PasswordReset = ({passwordResetMessage}) => {
  const {t} = useTranslation();
  return (
    <div className="authentication-wrapper authentication-basic px-4">
      <div className="authentication-inner py-4">
        <div className="card">
          <div className="card-body">
            <div className="app-brand justify-content-center mb-4 mt-2">
              <span className="app-brand-link">
                <img src="/images/app-logo-vertical.png" alt="Reward Logo" className="img-fluid"/>
              </span>
            </div>
            <h4 className="mb-1 pt-2">{t("password_reset_message_screen.password_reset")}</h4>
            <p className="text-start mb-4">
              {t("password_reset_message_screen.success_message")}
            </p>
            <Link to="/login" className="btn btn-primary w-100 mb-3">{t("buttons.continue")}</Link>
          </div>
        </div>
      </div>
    </div>
  )
}