import { Offcanvas } from "react-bootstrap";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { ResponseModal } from "../Employee/responseModal";
import { ApiWrapper } from "../../Api/apiWrapper";
import { INVALID_CODES, REQUEST_FAILED } from "../../Api/constants";

export const RequestMerchant = ({ onClose }) => {
  const user = useSelector((state) => state.user.user);
  const localData = JSON.parse(localStorage.getItem("data"));

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showResponseModal, setShowResponseModal] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const requestData = JSON.stringify({
        company_code: user.company_code,
        session_token: localData.session_token,
        locale: localStorage.getItem("i18nextLng"),
      });
      const data = await ApiWrapper({
        path: "merchant/v1/requestmerchants",
        method: "POST",
        data: requestData,
      });
      if (INVALID_CODES.includes(data.status_code) || data.errorMessage) {
        setErrorMessage(
          data.errorMessage || data.message || "Something went wrong!"
        );
      }
    } catch (error) {
      console.log({ error });
      setErrorMessage("Something went wrong!");
    } finally {
      setLoading(false);
      setShowResponseModal(true);
    }
  };

  return (
    <>
      <Offcanvas show={true} onHide={onClose} placement="end">
        {loading ? (
          <div className="pageLoader d-flex align-items-center justify-content-center position-absolute">
            <div className="spinner-border spinner-border-lg text-primary"></div>
          </div>
        ) : (
          ""
        )}
        {showResponseModal ? (
          <ResponseModal
            toggleMainComponent={onClose}
            heading={errorMessage ? "Error" : "Request Sent"}
            operation={errorMessage ? REQUEST_FAILED : ""}
            message={
              errorMessage
                ? errorMessage
                : "For additional merchants has been successfully submitted to our loyalty manager."
            }
          />
        ) : (
          <div className="d-flex flex-column h-100">
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Request Merchants</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div className="pt-5 d-flex justify-content-center">
                <span className={`badge rounded-circle bg-label-primary light-green p-5`}>
                  <i style={{ fontSize: '50px' }} className={`ti ti-building-store`}></i>
                </span>
              </div>
              <div
                className="mt-5 mx-4 d-flex justify-content-center text-center "
                style={{
                  fontSize: "15px",
                  fontWeight: "400",
                  lineHeight: "22px",
                }}
              >
                We shall promptly forward your request for merchants to loyalty
                manager. He will be in touch with you shortly to discuss further
                particulars.
              </div>
              <div className="bottom-btnWrap d-flex justify-content-between gap-3 position-absolute bottom-0 start-0 end-0 pb-4 ps-4 pe-4">
                <button
                  onClick={handleSubmit}
                  className="btn btn-primary d-grid w-100"
                >
                  Send request to the loyalty manager
                </button>
              </div>
            </Offcanvas.Body>
          </div>
        )}
      </Offcanvas>
    </>
  );
};
