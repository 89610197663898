import React from "react";
export const TotalKeys = ({ totalPurchasedKeys }) => {
  return (
    <div className="col-sm-6 col-xl-3">
      <div className="card">
        <div className="card-body">
          <div className="d-flex align-items-start justify-content-between">
            <div className="content-left">
              <span>{totalPurchasedKeys?.keys_tile_title}</span>
              <div className="d-flex align-items-center my-2">
                <h4 className="mb-0 me-2">
                  {totalPurchasedKeys?.keys_count?.toLocaleString()}
                </h4>
              </div>
              <p className="mb-0">{totalPurchasedKeys?.keys_tile_subtitle}</p>
            </div>
            <span className="badge bg-label-primary color-blue rounded p-2">
              <i className="ti ti-key ti-sm"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
