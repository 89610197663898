export const Content = () => {
  return (
    <>
      <div className="layout-page">
        <div className="content-wrapper">
          <div className="container-fluid flex-grow-1 container-p-y">
            <div className="row g-4 mb-4">
              {/*<Users/>*/}
              {/*<Offers/>*/}
              {/*<Redemptions/>*/}
              {/*<Savings/>*/}
            </div>
            {/*<NoEmploy/>*/}
          </div>
        </div>
      </div>
    </>
  )
}