import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ApiWrapper } from "../../../Api/apiWrapper";
import { SUCCESS_CODE } from "../../../Api/constants";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { useTranslation } from "react-i18next";

export const TopBar = ({ openSider }) => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user.user);
  const localData = JSON.parse(localStorage.getItem("data"));
  const config = useSelector((state) => state?.config?.config?.dashboard || {});

  let path = window.location.pathname;
  if (path === "/dashboard") {
    path = t("dashboard.page_title");
  }
  if (path === "/profile") {
    path = "Profile";
  }
  if (path === "/employee" || path.includes("employee/detail")) {
    path = "Employees";
  }
  if (path === "/merchants") {
    path = "Merchants";
  }
  if (path === "/content") {
    path = "Content";
  }
  if(path.includes('/merchant/detail/')){
    let decodedMerchant = decodeURIComponent(window.location.pathname.split('/')[4])
    path = `Merchants/${decodedMerchant}`
  }
  
  

  const navigation = useNavigate();
  const onLogoutHandler = async () => {
    localStorage.clear();
    navigation("/login");
    // const requestData = JSON.stringify({
    //   company_code: user.company_code,
    //   session_token: localData.session_token,
    //   locale: localStorage.getItem("i18nextLng")
    // });
    //   const data = await ApiWrapper({
    //     path:"/auth/v1/logout",
    //     method:"POST",
    //     data:requestData
    //   });
    //   if(data.status_code === SUCCESS_CODE) {
    //     localStorage.clear();
    //     navigation("/login");
    //   }
  };

  const storedLanguage = localStorage.getItem("selectedLanguage");
  const [activeLanguage, setActiveLanguage] = useState(storedLanguage || "en");

  const handleLanguageSelect = (language) => {
    setActiveLanguage(language);
    localStorage.setItem("selectedLanguage", language); // Save selected language to localStorage
  };

  useEffect(() => {
    // Set language attributes on initial load
    document.documentElement.lang = activeLanguage === "en" ? "en" : "ar";
    document.documentElement.dir = activeLanguage === "en" ? "ltr" : "rtl";
  }, [activeLanguage]); // Run this effect whenever activeLanguage changes

  return (
    <nav
      className="layout-navbar container-fluid navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme"
      id="layout-navbar"
    >
      <div
        className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none"
        onClick={() => openSider()}
      >
        <span className="nav-item nav-link px-0 me-xl-4">
          <i className="ti ti-menu-2 ti-sm"></i>
        </span>
      </div>

      <div
        className="navbar-nav-right d-flex align-items-center"
        id="navbar-collapse"
      >
        <div className="navbar-nav align-items-center">
          <div className="nav-item mb-0">
            <h5 className="mb-0">{path}</h5>
          </div>
        </div>

        <ul className="navbar-nav flex-row align-items-center ms-auto">
          {/* <li className="nav-item dropdown-language dropdown me-1">
            <DropdownButton variant='text' align={'end'} title={<i className="ti ti-language rounded-circle ti-md"></i>}>
                <Dropdown.Item className={activeLanguage === 'en' ? 'active' : ''}
                  onClick={() => handleLanguageSelect('en')}>
                  <span className="align-middle">English</span>
                </Dropdown.Item>
                <Dropdown.Item className={activeLanguage === 'ar' ? 'active' : ''}
                  onClick={() => handleLanguageSelect('ar')}>
                  <span className="align-middle">Arabic</span>
                </Dropdown.Item>
            </DropdownButton>
          </li> */}
          {/*Notification*/}
          <li className="nav-item dropdown-notifications navbar-dropdown dropdown me-1">
            {/*<DropdownButton variant='text' align={'end'} title={<><i className="ti ti-bell ti-md"></i>*/}
            {/*<span className="badge bg-danger rounded-pill badge-notifications">5</span></>}>*/}
            {/*  <ul className="dropdown-menu-end p-0">*/}
            {/*    <li className="dropdown-menu-header border-bottom">*/}
            {/*      <div className="dropdown-header d-flex align-items-center py-3">*/}
            {/*        <h5 className="text-body mb-0 me-auto">Notification</h5>*/}
            {/*        <span*/}
            {/*          className="dropdown-notifications-all text-body"*/}
            {/*          data-bs-toggle="tooltip"*/}
            {/*          data-bs-placement="top"*/}
            {/*          title="Mark all as read"*/}
            {/*        ><i className="ti ti-mail-opened fs-4"></i*/}
            {/*        ></span>*/}
            {/*      </div>*/}
            {/*    </li>*/}
            {/*    <li className="dropdown-notifications-list scrollable-container ps">*/}
            {/*      <ul className="list-group list-group-flush">*/}
            {/*        <li className="list-group-item list-group-item-action dropdown-notifications-item">*/}
            {/*          <div className="d-flex">*/}
            {/*            <div className="flex-shrink-0 me-3">*/}
            {/*              <div className="avatar">*/}
            {/*                <img src="/images/avatars/1.png" alt="avatar" className="h-auto rounded-circle"/>*/}
            {/*              </div>*/}
            {/*            </div>*/}
            {/*            <div className="flex-grow-1">*/}
            {/*              <h6 className="mb-1">Congratulation Lettie</h6>*/}
            {/*              <p className="mb-0">Won the monthly best seller gold badge</p>*/}
            {/*              <small className="text-muted">1h ago</small>*/}
            {/*            </div>*/}
            {/*            <div className="flex-shrink-0 dropdown-notifications-actions">*/}
            {/*              <p className="dropdown-notifications-read"*/}
            {/*              ><span className="badge badge-dot"></span*/}
            {/*              ></p>*/}
            {/*              <p className="dropdown-notifications-archive"*/}
            {/*              ><span className="ti ti-x"></span*/}
            {/*              ></p>*/}
            {/*            </div>*/}
            {/*          </div>*/}
            {/*        </li>*/}
            {/*        <li className="list-group-item list-group-item-action dropdown-notifications-item">*/}
            {/*          <div className="d-flex">*/}
            {/*            <div className="flex-shrink-0 me-3">*/}
            {/*              <div className="avatar">*/}
            {/*                <span className="avatar-initial rounded-circle bg-label-danger">CF</span>*/}
            {/*              </div>*/}
            {/*            </div>*/}
            {/*            <div className="flex-grow-1">*/}
            {/*              <h6 className="mb-1">Charles Franklin</h6>*/}
            {/*              <p className="mb-0">Accepted your connection</p>*/}
            {/*              <small className="text-muted">12hr ago</small>*/}
            {/*            </div>*/}
            {/*            <div className="flex-shrink-0 dropdown-notifications-actions">*/}
            {/*              <p className="dropdown-notifications-read"*/}
            {/*              ><span className="badge badge-dot"></span*/}
            {/*              ></p>*/}
            {/*              <p className="dropdown-notifications-archive"*/}
            {/*              ><span className="ti ti-x"></span*/}
            {/*              ></p>*/}
            {/*            </div>*/}
            {/*          </div>*/}
            {/*        </li>*/}
            {/*        <li className="list-group-item list-group-item-action dropdown-notifications-item marked-as-read">*/}
            {/*          <div className="d-flex">*/}
            {/*            <div className="flex-shrink-0 me-3">*/}
            {/*              <div className="avatar">*/}
            {/*                <img src="./images/avatars/2.png" alt="avatar" className="h-auto rounded-circle"/>*/}
            {/*              </div>*/}
            {/*            </div>*/}
            {/*            <div className="flex-grow-1">*/}
            {/*              <h6 className="mb-1">New Message</h6>*/}
            {/*              <p className="mb-0">You have new message from Natalie</p>*/}
            {/*              <small className="text-muted">1h ago</small>*/}
            {/*            </div>*/}
            {/*            <div className="flex-shrink-0 dropdown-notifications-actions">*/}
            {/*              <p  className="dropdown-notifications-read"*/}
            {/*              ><span className="badge badge-dot"></span*/}
            {/*              ></p>*/}
            {/*              <p  className="dropdown-notifications-archive"*/}
            {/*              ><span className="ti ti-x"></span*/}
            {/*              ></p>*/}
            {/*            </div>*/}
            {/*          </div>*/}
            {/*        </li>*/}
            {/*        <li className="list-group-item list-group-item-action dropdown-notifications-item">*/}
            {/*          <div className="d-flex">*/}
            {/*            <div className="flex-shrink-0 me-3">*/}
            {/*              <div className="avatar">*/}
            {/*                      <span className="avatar-initial rounded-circle bg-label-success"*/}
            {/*                      ><i className="ti ti-shopping-cart"></i*/}
            {/*                      ></span>*/}
            {/*              </div>*/}
            {/*            </div>*/}
            {/*            <div className="flex-grow-1">*/}
            {/*              <h6 className="mb-1">Whoo! You have new order</h6>*/}
            {/*              <p className="mb-0">ACME Inc. made new order $1,154</p>*/}
            {/*              <small className="text-muted">1 day ago</small>*/}
            {/*            </div>*/}
            {/*            <div className="flex-shrink-0 dropdown-notifications-actions">*/}
            {/*              <p  className="dropdown-notifications-read"*/}
            {/*              ><span className="badge badge-dot"></span*/}
            {/*              ></p>*/}
            {/*              <p className="dropdown-notifications-archive"*/}
            {/*              ><span className="ti ti-x"></span*/}
            {/*              ></p>*/}
            {/*            </div>*/}
            {/*          </div>*/}
            {/*        </li>*/}
            {/*        <li className="list-group-item list-group-item-action dropdown-notifications-item marked-as-read">*/}
            {/*          <div className="d-flex">*/}
            {/*            <div className="flex-shrink-0 me-3">*/}
            {/*              <div className="avatar">*/}
            {/*                <img src="/images/avatars/9.png" alt="avatar" className="h-auto rounded-circle"/>*/}
            {/*              </div>*/}
            {/*            </div>*/}
            {/*            <div className="flex-grow-1">*/}
            {/*              <h6 className="mb-1">Application has been approved</h6>*/}
            {/*              <p className="mb-0">Your ABC project application has been approved.</p>*/}
            {/*              <small className="text-muted">2 days ago</small>*/}
            {/*            </div>*/}
            {/*            <div className="flex-shrink-0 dropdown-notifications-actions">*/}
            {/*              <p className="dropdown-notifications-read"*/}
            {/*              ><span className="badge badge-dot"></span*/}
            {/*              ></p>*/}
            {/*              <p className="dropdown-notifications-archive"*/}
            {/*              ><span className="ti ti-x"></span*/}
            {/*              ></p>*/}
            {/*            </div>*/}
            {/*          </div>*/}
            {/*        </li>*/}
            {/*        <li className="list-group-item list-group-item-action dropdown-notifications-item marked-as-read">*/}
            {/*          <div className="d-flex">*/}
            {/*            <div className="flex-shrink-0 me-3">*/}
            {/*              <div className="avatar">*/}
            {/*                      <span className="avatar-initial rounded-circle bg-label-success"*/}
            {/*                      ><i className="ti ti-chart-pie"></i*/}
            {/*                      ></span>*/}
            {/*              </div>*/}
            {/*            </div>*/}
            {/*            <div className="flex-grow-1">*/}
            {/*              <h6 className="mb-1">Monthly report is generated</h6>*/}
            {/*              <p className="mb-0">July monthly financial report is generated</p>*/}
            {/*              <small className="text-muted">3 days ago</small>*/}
            {/*            </div>*/}
            {/*            <div className="flex-shrink-0 dropdown-notifications-actions">*/}
            {/*              <p className="dropdown-notifications-read"*/}
            {/*              ><span className="badge badge-dot"></span*/}
            {/*              ></p>*/}
            {/*              <p className="dropdown-notifications-archive"*/}
            {/*              ><span className="ti ti-x"></span*/}
            {/*              ></p>*/}
            {/*            </div>*/}
            {/*          </div>*/}
            {/*        </li>*/}
            {/*        <li className="list-group-item list-group-item-action dropdown-notifications-item marked-as-read">*/}
            {/*          <div className="d-flex">*/}
            {/*            <div className="flex-shrink-0 me-3">*/}
            {/*              <div className="avatar">*/}
            {/*                <img src="/images/avatars/5.png" alt="avatar" className="h-auto rounded-circle"/>*/}
            {/*              </div>*/}
            {/*            </div>*/}
            {/*            <div className="flex-grow-1">*/}
            {/*              <h6 className="mb-1">Send connection request</h6>*/}
            {/*              <p className="mb-0">Peter sent you connection request</p>*/}
            {/*              <small className="text-muted">4 days ago</small>*/}
            {/*            </div>*/}
            {/*            <div className="flex-shrink-0 dropdown-notifications-actions">*/}
            {/*              <p className="dropdown-notifications-read"*/}
            {/*              ><span className="badge badge-dot"></span*/}
            {/*              ></p>*/}
            {/*              <p className="dropdown-notifications-archive"*/}
            {/*              ><span className="ti ti-x"></span*/}
            {/*              ></p>*/}
            {/*            </div>*/}
            {/*          </div>*/}
            {/*        </li>*/}
            {/*        <li className="list-group-item list-group-item-action dropdown-notifications-item">*/}
            {/*          <div className="d-flex">*/}
            {/*            <div className="flex-shrink-0 me-3">*/}
            {/*              <div className="avatar">*/}
            {/*                <img src="/images/avatars/6.png" alt="avatar" className="h-auto rounded-circle"/>*/}
            {/*              </div>*/}
            {/*            </div>*/}
            {/*            <div className="flex-grow-1">*/}
            {/*              <h6 className="mb-1">New message from Jane</h6>*/}
            {/*              <p className="mb-0">Your have new message from Jane</p>*/}
            {/*              <small className="text-muted">5 days ago</small>*/}
            {/*            </div>*/}
            {/*            <div className="flex-shrink-0 dropdown-notifications-actions">*/}
            {/*              <p className="dropdown-notifications-read"*/}
            {/*              ><span className="badge badge-dot"></span*/}
            {/*              ></p>*/}
            {/*              <p className="dropdown-notifications-archive"*/}
            {/*              ><span className="ti ti-x"></span*/}
            {/*              ></p>*/}
            {/*            </div>*/}
            {/*          </div>*/}
            {/*        </li>*/}
            {/*        <li className="list-group-item list-group-item-action dropdown-notifications-item marked-as-read">*/}
            {/*          <div className="d-flex">*/}
            {/*            <div className="flex-shrink-0 me-3">*/}
            {/*              <div className="avatar">*/}
            {/*                      <span className="avatar-initial rounded-circle bg-label-warning"*/}
            {/*                      ><i className="ti ti-alert-triangle"></i*/}
            {/*                      ></span>*/}
            {/*              </div>*/}
            {/*            </div>*/}
            {/*            <div className="flex-grow-1">*/}
            {/*              <h6 className="mb-1">CPU is running high</h6>*/}
            {/*              <p className="mb-0">CPU Utilization Percent is currently at 88.63%,</p>*/}
            {/*              <small className="text-muted">5 days ago</small>*/}
            {/*            </div>*/}
            {/*            <div className="flex-shrink-0 dropdown-notifications-actions">*/}
            {/*              <p className="dropdown-notifications-read"*/}
            {/*              ><span className="badge badge-dot"></span*/}
            {/*              ></p>*/}
            {/*              <p className="dropdown-notifications-archive"*/}
            {/*              ><span className="ti ti-x"></span*/}
            {/*              ></p>*/}
            {/*            </div>*/}
            {/*          </div>*/}
            {/*        </li>*/}
            {/*      </ul>*/}
            {/*    </li>*/}
            {/*    <li className="dropdown-menu-footer border-top">*/}
            {/*      <Link*/}
            {/*        className="dropdown-item d-flex justify-content-center text-primary align-items-center">*/}
            {/*        View all notifications*/}
            {/*      </Link>*/}
            {/*    </li>*/}
            {/*  </ul>*/}
            {/*</DropdownButton>*/}
          </li>
          {/*Notification*/}

          {/*User*/}
          <li className="nav-item navbar-dropdown dropdown-user dropdown">
            <DropdownButton
              variant="text"
              align={"end"}
              title={
                <span className="nav-link dropdown-toggle hide-arrow">
                  <div className="avatar avatar-online">
                    <img
                      style={{ objectFit: "cover" }}
                      src={
                        user.profile_img || "/images/default-profile-img.png"
                      }
                      alt="avatar"
                      className="w-100 h-100 d-block rounded-circle"
                    />
                  </div>
                </span>
              }
            >
              <ul className="dropdown-menu-end p-0">
                <li>
                  <span className="dropdown-item">
                    <div className="d-flex">
                      <div className="flex-shrink-0 me-3">
                        <div className="avatar avatar-online">
                          <img
                            style={{ objectFit: "cover" }}
                            src={
                              user.profile_img ||
                              "/images/default-profile-img.png"
                            }
                            alt="avatar"
                            className="h-100 w-100 d-block rounded-circle"
                          />
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <span className="fw-medium d-block">
                          {user.first_name}
                        </span>
                        <small className="text-muted">{user.role_name}</small>
                      </div>
                    </div>
                  </span>
                  <div className="dropdown-divider"></div>
                </li>
                <li>
                  <Link
                    to="/profile"
                    className="dropdown-item d-flex align-items-center"
                  >
                    <i className="ti ti-user me-2 ti-sm"></i>
                    <span className="align-middle">My Profile</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item d-flex align-items-center"
                    to={
                      config.faq_link ||
                      "https://www.theentertainerme.com/rwd-faqs"
                    }
                    target="_blank"
                  >
                    <i className="ti ti-help me-2 ti-sm"></i>
                    <span className="align-middle">Help</span>
                  </Link>
                </li>
                <li>
                  <div className="dropdown-divider"></div>
                  <button className="dropdown-item" onClick={onLogoutHandler}>
                    <i className="ti ti-logout me-2 ti-sm"></i>
                    <span className="align-middle">Log Out</span>
                  </button>
                </li>
              </ul>
            </DropdownButton>
          </li>
          {/*User*/}
        </ul>
      </div>

      {/*Search Small Screens*/}
      <div className="navbar-search-wrapper search-input-wrapper d-none">
        <input
          type="text"
          className="form-control search-input container-xxl border-0"
          placeholder="Search..."
          aria-label="Search..."
        />
        <i className="ti ti-x ti-sm search-toggler cursor-pointer"></i>
      </div>
    </nav>
  );
};
