import React, { useEffect, useState } from "react";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import { NISSAN_COMPANY_CODE, SUCCESS_CODE } from "../../../Api/constants";
import { ApiWrapper } from "../../../Api/apiWrapper";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import NoDataComponent from "../../Shared/noDataComponent";

const data = [
  {
    name: "Jan",
    savings: 4000,
  },
  {
    name: "Fab",
    savings: 3000,
  },
  {
    name: "Mar",
    savings: 2000,
  },
  {
    name: "Apr",
    savings: 2780,
  },
  {
    name: "May",
    savings: 18,
  },
  {
    name: "Jun",
    savings: 2390,
  },
  {
    name: "Jul",
    savings: 3490,
  },
  {
    name: "Aug",
    savings: 3490,
  },
  {
    name: "Sep",
    savings: 3490,
  },
  {
    name: "Jan",
    savings: 4000,
  },
  {
    name: "Fab",
    savings: 3000,
  },
  {
    name: "Mar",
    savings: 2000,
  },
  {
    name: "Apr",
    savings: 2780,
  },
  {
    name: "May",
    savings: 18,
  },
  {
    name: "Jun",
    savings: 2390,
  },
  {
    name: "Jul",
    savings: 3490,
  },
  {
    name: "Aug",
    savings: 3490,
  },
  {
    name: "Sep",
    savings: 3490,
  },
  {
    name: "Jan",
    savings: 4000,
  },
  {
    name: "Fab",
    savings: 3000,
  },
  {
    name: "Mar",
    savings: 2000,
  },
  {
    name: "Apr",
    savings: 2780,
  },
  {
    name: "May",
    savings: 18,
  },
  {
    name: "Jun",
    savings: 2390,
  },
  {
    name: "Jul",
    savings: 3490,
  },
  {
    name: "Aug",
    savings: 3490,
  },
  {
    name: "Sep",
    savings: 3490,
  },
  {
    name: "Jan",
    savings: 4000,
  },
  {
    name: "Fab",
    savings: 3000,
  },
  {
    name: "Mar",
    savings: 2000,
  },
  {
    name: "Apr",
    savings: 2780,
  },
  {
    name: "May",
    savings: 18,
  },
  {
    name: "Jun",
    savings: 2390,
  },
  {
    name: "Jul",
    savings: 3490,
  },
  {
    name: "Aug",
    savings: 3490,
  },
  {
    name: "Sep",
    savings: 3490,
  },
  {
    name: "Jan",
    savings: 4000,
  },
  {
    name: "Fab",
    savings: 3000,
  },
  {
    name: "Mar",
    savings: 2000,
  },
  {
    name: "Apr",
    savings: 2780,
  },
  {
    name: "May",
    savings: 18,
  },
  {
    name: "Jun",
    savings: 2390,
  },
  {
    name: "Jul",
    savings: 3490,
  },
  {
    name: "Aug",
    savings: 3490,
  },
  {
    name: "Sep",
    savings: 3490,
  },
  {
    name: "Jan",
    savings: 4000,
  },
  {
    name: "Fab",
    savings: 3000,
  },
  {
    name: "Mar",
    savings: 2000,
  },
  {
    name: "Apr",
    savings: 2780,
  },
  {
    name: "May",
    savings: 18,
  },
  {
    name: "Jun",
    savings: 2390,
  },
  {
    name: "Jul",
    savings: 3490,
  },
  {
    name: "Aug",
    savings: 3490,
  },
  {
    name: "Sep",
    savings: 3490,
  },
  {
    name: "Jan",
    savings: 4000,
  },
  {
    name: "Fab",
    savings: 3000,
  },
  {
    name: "Mar",
    savings: 2000,
  },
  {
    name: "Apr",
    savings: 2780,
  },
  {
    name: "May",
    savings: 18,
  },
  {
    name: "Jun",
    savings: 2390,
  },
  {
    name: "Jul",
    savings: 3490,
  },
  {
    name: "Aug",
    savings: 3490,
  },
  {
    name: "Sep",
    savings: 3490,
  },
  {
    name: "Jan",
    savings: 4000,
  },
  {
    name: "Fab",
    savings: 3000,
  },
  {
    name: "Mar",
    savings: 2000,
  },
  {
    name: "Apr",
    savings: 2780,
  },
  {
    name: "May",
    savings: 18,
  },
  {
    name: "Jun",
    savings: 2390,
  },
  {
    name: "Jul",
    savings: 3490,
  },
  {
    name: "Aug",
    savings: 3490,
  },
  {
    name: "Sep",
    savings: 3490,
  },
  {
    name: "Jan",
    savings: 4000,
  },
  {
    name: "Fab",
    savings: 3000,
  },
  {
    name: "Mar",
    savings: 2000,
  },
  {
    name: "Apr",
    savings: 2780,
  },
  {
    name: "May",
    savings: 18,
  },
  {
    name: "Jun",
    savings: 2390,
  },
  {
    name: "Jul",
    savings: 3490,
  },
  {
    name: "Aug",
    savings: 3490,
  },
  {
    name: "Sep",
    savings: 3490,
  },
  {
    name: "Jan",
    savings: 4000,
  },
  {
    name: "Fab",
    savings: 3000,
  },
  {
    name: "Mar",
    savings: 2000,
  },
  {
    name: "Apr",
    savings: 2780,
  },
  {
    name: "May",
    savings: 18,
  },
  {
    name: "Jun",
    savings: 2390,
  },
  {
    name: "Jul",
    savings: 3490,
  },
  {
    name: "Aug",
    savings: 3490,
  },
  {
    name: "Sep",
    savings: 3490,
  },
  {
    name: "Jan",
    savings: 4000,
  },
  {
    name: "Fab",
    savings: 3000,
  },
  {
    name: "Mar",
    savings: 2000,
  },
  {
    name: "Apr",
    savings: 2780,
  },
  {
    name: "May",
    savings: 18,
  },
  {
    name: "Jun",
    savings: 2390,
  },
  {
    name: "Jul",
    savings: 3490,
  },
  {
    name: "Aug",
    savings: 3490,
  },
  {
    name: "Sep",
    savings: 3490,
  },
  {
    name: "Jan",
    savings: 4000,
  },
  {
    name: "Fab",
    savings: 3000,
  },
  {
    name: "Mar",
    savings: 2000,
  },
  {
    name: "Apr",
    savings: 2780,
  },
  {
    name: "May",
    savings: 18,
  },
  {
    name: "Jun",
    savings: 2390,
  },
  {
    name: "Jul",
    savings: 3490,
  },
  {
    name: "Aug",
    savings: 3490,
  },
  {
    name: "Sep",
    savings: 3490,
  },
  {
    name: "Jan",
    savings: 4000,
  },
  {
    name: "Fab",
    savings: 3000,
  },
  {
    name: "Mar",
    savings: 2000,
  },
  {
    name: "Apr",
    savings: 2780,
  },
  {
    name: "May",
    savings: 18,
  },
  {
    name: "Jun",
    savings: 2390,
  },
  {
    name: "Jul",
    savings: 3490,
  },
  {
    name: "Aug",
    savings: 3490,
  },
  {
    name: "Sep",
    savings: 3490,
  },
  {
    name: "Jan",
    savings: 4000,
  },
  {
    name: "Fab",
    savings: 3000,
  },
  {
    name: "Mar",
    savings: 2000,
  },
  {
    name: "Apr",
    savings: 2780,
  },
  {
    name: "May",
    savings: 18,
  },
  {
    name: "Jun",
    savings: 2390,
  },
  {
    name: "Jul",
    savings: 3490,
  },
  {
    name: "Aug",
    savings: 3490,
  },
  {
    name: "Sep",
    savings: 3490,
  },
  {
    name: "Jan",
    savings: 4000,
  },
  {
    name: "Fab",
    savings: 3000,
  },
  {
    name: "Mar",
    savings: 2000,
  },
  {
    name: "Apr",
    savings: 2780,
  },
  {
    name: "May",
    savings: 18,
  },
  {
    name: "Jun",
    savings: 2390,
  },
  {
    name: "Jul",
    savings: 3490,
  },
  {
    name: "Aug",
    savings: 3490,
  },
  {
    name: "Sep",
    savings: 3490,
  },
  {
    name: "Jan",
    savings: 4000,
  },
  {
    name: "Fab",
    savings: 3000,
  },
  {
    name: "Mar",
    savings: 2000,
  },
  {
    name: "Apr",
    savings: 2780,
  },
  {
    name: "May",
    savings: 18,
  },
  {
    name: "Jun",
    savings: 2390,
  },
  {
    name: "Jul",
    savings: 3490,
  },
  {
    name: "Aug",
    savings: 3490,
  },
  {
    name: "Sep",
    savings: 3490,
  },
  {
    name: "Jan",
    savings: 4000,
  },
  {
    name: "Fab",
    savings: 3000,
  },
  {
    name: "Mar",
    savings: 2000,
  },
  {
    name: "Apr",
    savings: 2780,
  },
  {
    name: "May",
    savings: 18,
  },
  {
    name: "Jun",
    savings: 2390,
  },
  {
    name: "Jul",
    savings: 3490,
  },
  {
    name: "Aug",
    savings: 3490,
  },
  {
    name: "Sep",
    savings: 3490,
  },
];

export const RedemptionTracking = () => {
  const { t } = useTranslation();

  const [redemptionTracking, setRedemptionTracking] = useState([]);
  const [loading, setLoading] = useState(true);
  const user = useSelector((state) => state.user.user);

  const today = new Date();
  const getFullYear = today.getFullYear();
  const yearStart = new Date(getFullYear, 0, 1);
  const yearEnd = new Date(getFullYear, 11, 31);
  const [year, setYear] = useState(getFullYear);

  // Create an array of the previous 10 years
  const previousTenYears = Array.from(
    { length: 10 },
    (_, index) => getFullYear - index
  );

  const fetchRedemptionTracking = async (sDate, eDate) => {
    const localData = JSON.parse(localStorage.getItem("data"));
    const requestData = JSON.stringify({
      company_code: user?.company_code,
      session_token: localData.session_token,
      locale: localStorage.getItem("i18nextLng"),
      start_date:
        sDate.getFullYear() +
        "-" +
        (sDate.getMonth() + 1) +
        "-" +
        sDate.getDate(),
      end_date:
        eDate.getFullYear() +
        "-" +
        (eDate.getMonth() + 1) +
        "-" +
        eDate.getDate(),
    });
    const data = await ApiWrapper({
      path: "/dashboard/v1/getredemptionsanalytics",
      method: "POST",
      data: requestData,
    });
    if (data.status_code === SUCCESS_CODE) {
      const jsonData = JSON.stringify(data.data);
      const parsedData = JSON.parse(jsonData);
      let updatedObject = [];
      Object.keys(parsedData.analytics).forEach((oldKey) => {
        const newKey = oldKey;
        updatedObject[newKey] = parsedData.analytics[oldKey];
        updatedObject[newKey]["name"] = oldKey;
        updatedObject = Object.values(updatedObject);
      });
      setRedemptionTracking(updatedObject);
      setLoading(false);
    } else {
      console.log("something went wrong");
    }
  };

  useEffect(() => {
    fetchRedemptionTracking(yearStart, yearEnd);
  }, []);

  const redemptionYearHandler = (event) => {
    const year = event.target.value;
    const firstDate = new Date(year, 0, 1);
    const lastDate = new Date(year, 11, 31);
    setYear(event.target.value);
    fetchRedemptionTracking(firstDate, lastDate);
  };

  return (
    <div className="row g-4 mb-4">
      <div className="col-12">
        <div className="card">
          <div className="card-header header-elements mb-3">
            <h4 className="card-title mb-0">
              {t("redemption_tracking.redmp_title")}
            </h4>
            <div className="card-action-element d-flex ms-auto py-0">
              {/*<div className='selectHolder'>*/}
              {/*  <select className="form-select" style={ { minWidth: '124px' } }>*/}
              {/*    <option>Junior Level</option>*/}
              {/*    <option>Mid Level</option>*/}
              {/*    <option>Senior Level</option>*/}
              {/*  </select>*/}
              {/*</div>*/}
              <div className="selectHolder mx-3">
                <select
                  className="form-select"
                  value={year}
                  onChange={redemptionYearHandler}
                  style={{ minWidth: "124px" }}
                >
                  {previousTenYears?.map((year, index) => (
                    <option key={index} value={year}>
                      {year === new Date().getFullYear()
                        ? "This Year"
                        : `${year}`}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          {!loading && (
            <div className="keysOverviewChart">
              <ResponsiveContainer width={"100%"} height={385} min-width={300}>
                <BarChart
                  barCategoryGap={0}
                  width={500}
                  height={300}
                  data={redemptionTracking}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Bar dataKey="redemption" fill="#80C100" minPointSize={5} />
                </BarChart>
              </ResponsiveContainer>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
