import DropdownButton from "react-bootstrap/DropdownButton";

export const MerchantInfo = ({ merchantDetail }) => {
  const profileImage = merchantDetail.logo || "/images/default-profile-img.png";

  return (
    <>
      <div className="col-lg-4 col-md-4 order-1 order-md-0">
        <div className="card mb-4">
          <div className="card-body">
            <div className="editProfile d-flex justify-content-end mb-3">
              <DropdownButton
                style={{ marginRight: "-9px" }}
                className="iconBtn"
                variant="text"
                align={"end"}
                disabled
                title={
                  <>
                    <i className="ti ti-dots-vertical ti-sm cursor-pointer"></i>
                  </>
                }
              ></DropdownButton>
            </div>
            <div className="user-avatar-section pb-2 border-bottom">
              <div className="d-flex align-items-center flex-column">
                <div className="userImg-holder position-relative mb-4">
                  <div className="overflow-hidden rounded userImg-holder">
                    <img
                      src={profileImage}
                      alt="Merchant logo"
                      height="100"
                      width="100"
                      className="img-fluid userImg"
                    />
                  </div>
                </div>
                <div className="user-info text-center">
                  <h4 className="mb-2">{merchantDetail.merchant_name}</h4>
                  <p>{merchantDetail.email}</p>
                  <span className="badge bg-label-success">
                    {/* {merchantDetail.status} */ "Active"}
                  </span>
                </div>
              </div>
              <div className="userList-info mt-4">
                <div className="row align-items-start justify-content-between">
                  <div className="col-md-6 col-12 pe-2 mb-3">
                    <div className="item d-flex align-items-center bg-label-secondary rounded py-3 px-2 w-100">
                      <span className="icon text-primary">
                        <i className="ti ti-id ti-lg"></i>
                      </span>
                      <div className="text ps-2">
                        <h6 className="mb-0">
                          {merchantDetail.merchant_id ?? 0}
                        </h6>
                        <p className="text-muted mb-0">Merchant ID</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-12 ps-2 mb-3">
                    <div className="item d-flex align-items-center bg-label-secondary rounded py-3 px-2 w-100">
                      <span className="icon text-primary">
                        <i className="ti ti-users ti-lg"></i>
                      </span>
                      <div className="text ps-2">
                        <h6 className="mb-0">
                          {merchantDetail.redemption_count}
                        </h6>
                        <p className="text-muted mb-0">Redemptions</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="info-container pt-3">
              <ul className="list-unstyled">
                <li className="mb-2">
                  <span className="fw-medium me-1">Account Name:</span>
                  <span>#{merchantDetail.merchant_name}</span>
                </li>
                <li className="mb-2 pt-1">
                  <span className="fw-medium me-1">Tier:</span>
                  <span>
                    {merchantDetail?.tiers?.map((tier, index, arr) => (
                      <span>
                        {tier + (index + 1 < arr?.length ? ", " : "")}
                      </span>
                    ))}
                  </span>
                </li>
                <li className="mb-2 pt-1">
                  <span className="fw-medium me-1">Website:</span>
                  <span>{merchantDetail.website}</span>
                </li>
                <li className="mb-2 pt-1">
                  <span className="fw-medium me-1">Contact:</span>
                  <span>{merchantDetail.telephone}</span>
                </li>
                <li className="mb-2 pt-1">
                  <span className="fw-medium me-1">Category:</span>
                  <span>{merchantDetail.category}</span>
                </li>
                <li className="mb-2 pt-1">
                  <span className="fw-medium me-1">Account Currency:</span>
                  <span>{merchantDetail.nationality}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
