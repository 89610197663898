const host = window.location.host;
let BASE_PATH;
let TOKEN_API_PATH;
let GRANT_TYPE;
let CLIENT_ID;
let CLIENT_SECRET;
let SCOPE;

if (host === "dvrewardscms.etenvbiz.com" || host === "localhost:3000") {
  // BASE_PATH = process.env.REACT_APP_PROD_API_BASE_URL;
  // TOKEN_API_PATH = process.env.REACT_APP_PROD_GET_AUTH_TOEKN_ENDPOINT;
  //
  // GRANT_TYPE = process.env.REACT_APP_PROD_API_GRANT_TYPE;
  // CLIENT_ID = process.env.REACT_APP_PROD_API_CLIENT_ID;
  // CLIENT_SECRET = process.env.REACT_APP_PROD_API_CLIENT_SECRET;
  // SCOPE = process.env.REACT_APP_PROD_API_SCOPE;

  //---------------------for dev-------------------
  // BASE_PATH = process.env.REACT_APP_DEV_API_BASE_URL;
  // TOKEN_API_PATH = process.env.REACT_APP_DEV_GET_AUTH_TOEKN_ENDPOINT;

  // GRANT_TYPE = process.env.REACT_APP_DEV_API_GRANT_TYPE;
  // CLIENT_ID = process.env.REACT_APP_DEV_API_CLIENT_ID;
  // CLIENT_SECRET = process.env.REACT_APP_DEV_API_CLIENT_SECRET;
  // SCOPE = process.env.REACT_APP_DEV_API_SCOPE;
  //-----------------------------------------------

  BASE_PATH = process.env.REACT_APP_UAT_API_BASE_URL;
  TOKEN_API_PATH = process.env.REACT_APP_UAT_GET_AUTH_TOEKN_ENDPOINT;

  GRANT_TYPE = process.env.REACT_APP_UAT_API_GRANT_TYPE;
  CLIENT_ID = process.env.REACT_APP_UAT_API_CLIENT_ID;
  CLIENT_SECRET = process.env.REACT_APP_UAT_API_CLIENT_SECRET;
  SCOPE = process.env.REACT_APP_UAT_API_SCOPE;

  ////////////
  // BASE_PATH = process.env.REACT_APP_UAT_API_BASE_URL;
  // TOKEN_API_PATH = process.env.REACT_APP_UAT_GET_AUTH_TOEKN_ENDPOINT;

  // GRANT_TYPE = process.env.REACT_APP_UAT_API_GRANT_TYPE;
  // CLIENT_ID = process.env.REACT_APP_UAT_API_CLIENT_ID;
  // CLIENT_SECRET = process.env.REACT_APP_UAT_API_CLIENT_SECRET;
  // SCOPE = process.env.REACT_APP_UAT_API_SCOPE;
  ///////////
} else if (host === "qarewardscms.etenvbiz.com") {
  BASE_PATH = process.env.REACT_APP_QA_API_BASE_URL;
  TOKEN_API_PATH = process.env.REACT_APP_QA_GET_AUTH_TOEKN_ENDPOINT;

  GRANT_TYPE = process.env.REACT_APP_QA_API_GRANT_TYPE;
  CLIENT_ID = process.env.REACT_APP_QA_API_CLIENT_ID;
  CLIENT_SECRET = process.env.REACT_APP_QA_API_CLIENT_SECRET;
  SCOPE = process.env.REACT_APP_QA_API_SCOPE;
} else if (host === "utrewardscms.theentertainerme.com") {
  BASE_PATH = process.env.REACT_APP_UAT_API_BASE_URL;
  TOKEN_API_PATH = process.env.REACT_APP_UAT_GET_AUTH_TOEKN_ENDPOINT;

  GRANT_TYPE = process.env.REACT_APP_UAT_API_GRANT_TYPE;
  CLIENT_ID = process.env.REACT_APP_UAT_API_CLIENT_ID;
  CLIENT_SECRET = process.env.REACT_APP_UAT_API_CLIENT_SECRET;
  SCOPE = process.env.REACT_APP_UAT_API_SCOPE;
} else if (host === "rewardscms.theentertainerme.com") {
  BASE_PATH = process.env.REACT_APP_PROD_API_BASE_URL;
  TOKEN_API_PATH = process.env.REACT_APP_PROD_GET_AUTH_TOEKN_ENDPOINT;

  GRANT_TYPE = process.env.REACT_APP_PROD_API_GRANT_TYPE;
  CLIENT_ID = process.env.REACT_APP_PROD_API_CLIENT_ID;
  CLIENT_SECRET = process.env.REACT_APP_PROD_API_CLIENT_SECRET;
  SCOPE = process.env.REACT_APP_PROD_API_SCOPE;
} else {
  BASE_PATH = process.env.REACT_APP_PROD_API_BASE_URL;
  TOKEN_API_PATH = process.env.REACT_APP_PROD_GET_AUTH_TOEKN_ENDPOINT;

  GRANT_TYPE = process.env.REACT_APP_DEV_API_GRANT_TYPE;
  CLIENT_ID = process.env.REACT_APP_DEV_API_CLIENT_ID;
  CLIENT_SECRET = process.env.REACT_APP_DEV_API_CLIENT_SECRET;
  SCOPE = process.env.REACT_APP_DEV_API_SCOPE;
}

export const ApiWrapper = async ({ basePath = null, path, method, data }) => {
  let token = localStorage.getItem("access_token");
  if (!token || !checkTokenValidity()) {
    token = await getToken();
  }
  let request = {
    method: method,
    headers: {
      "Content-Type": "application/json",
      AuthorizationToken: token,
    },
  };
  if (method === "POST") {
    request = {
      ...request,
      body: data,
    };
  }
  const response = await fetch(`${basePath || BASE_PATH}${path}`, request);
  return response.json();
};

const checkTokenValidity = () => {
  const currentTime = new Date();
  const expiryTime = new Date(localStorage.getItem("expiry"));
  return currentTime.getTime() > expiryTime.getTime() ? false : true;
};

export const getToken = async () => {
  const request = {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      AuthorizationToken: "",
    },
    body: new URLSearchParams({
      grant_type: GRANT_TYPE,
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
      scope: SCOPE,
    }).toString(),
  };
  const response = await fetch(TOKEN_API_PATH, request);
  const data = await response.json();
  const secToMin = data.expires_in / 60;
  const expiryTime = setExpiryTime(new Date(), secToMin).toString();
  localStorage.setItem("access_token", data.access_token);
  localStorage.setItem("expiry", expiryTime);
  return data.access_token;
};

const setExpiryTime = function (dateTime, expiryMinutes) {
  return new Date(dateTime.getTime() + expiryMinutes * 60000);
};
