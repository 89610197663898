import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  module: null,
};

const moduleSlice = createSlice({
  name: 'module',
  initialState,
  reducers: {
    setModule: (state, action) => {
      state.module = action.payload;
    },
    clearUser: (state) => {
      state.module = null;
    },
  },
});

export const { setModule, clearUser } = moduleSlice.actions;

export default moduleSlice.reducer;
