import { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useSelector } from "react-redux";
import { ApiWrapper } from "../Api/apiWrapper";
import { TopInfoCard } from "../Components/Merchant/topInfoCard";
import { TopMerchantCard } from "../Components/Merchant/TopMerchantCard";
import { MerchantsList } from "../Components/Merchant/merchantsList";
import { CategoryList } from "../Components/Merchant/categoryList";

export const Merchant = () => {
  const user = useSelector((state) => state.user.user);
  const localData = JSON.parse(localStorage.getItem("data"));
  const [loading, setLoading] = useState(false);
  const [activeMerchants, setActiveMerchants] = useState(0);
  const [totalOffers, setTotalOffers] = useState(0);
  const [totalCategories, setTotalCategories] = useState(0);
  const [topThreeMerchants, setTopThreeMerchants] = useState([]);
  const [merchantsList, setMerchantsList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);

  const merchantApiEndpoints = [
    "/merchant/v1/getmerchantscount",
    "/merchant/v1/gettotalofferscount",
    "/merchant/v1/getcategoriescount",
    "/merchant/v1/gettopthreemerchants",
    "/merchant/v1/getmerchantslist",
    "/merchant/v1/getcategorieslist",
  ];

  const fetchPageData = () => {
    const requestData = JSON.stringify({
      company_code: user.company_code,
      session_token: localData.session_token,
      locale: localStorage.getItem("i18nextLng"),
    });

    setLoading(true);
    const apiPromises = merchantApiEndpoints?.map((endpoint) =>
      ApiWrapper({
        path: endpoint,
        method: "POST",
        data: requestData,
      })
    );

    Promise.allSettled(apiPromises).then((response) => {
      setActiveMerchants(response[0]?.value?.data?.merchants_count || "");
      setTotalOffers(response[1]?.value?.data?.offers_count || "");
      setTotalCategories(response[2]?.value?.data?.categories_count || "");
      setTopThreeMerchants(response[3]?.value?.data?.merchants || []);
      setMerchantsList(response[4]?.value?.data?.merchants || []);
      setCategoryList(response[5]?.value?.data?.categories || []);
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchPageData();
  }, []);

  return (
    <>
      {loading ? (
        <div className="pageLoader d-flex align-items-center justify-content-center">
          <div className="spinner-border spinner-border-lg text-primary"></div>
        </div>
      ) : (
        <div className="layout-page">
          <div className="content-wrapper">
            <div className="container-fluid flex-grow-1 container-p-y">
              <div className="row">
                <div className="col col-8">
                  <div className="row h-100">
                    <TopInfoCard
                      icon="ti-building-store"
                      iconClasses="bg-label-primary light-green"
                      subTitle="Active Merchants"
                      count={activeMerchants}
                    />
                    <TopInfoCard
                      icon="ti-discount-2"
                      iconClasses="bg-label-danger"
                      subTitle="Total Number of Offers"
                      count={totalOffers}
                    />
                    <TopInfoCard
                      icon="ti-apps"
                      iconClasses="bg-label-primary light-blue"
                      subTitle="Total Categories"
                      count={totalCategories}
                    />
                  </div>
                </div>
                <div className="col col-4">
                  <TopMerchantCard merchants={topThreeMerchants} />
                </div>
              </div>
              <div className="mt-4">
                <Tabs
                  defaultActiveKey="security"
                  id="uncontrolled-tab-example"
                  className="nav nav-pills tabs bg-transparent border-0 flex-column flex-md-row mb-4"
                >
                  <Tab
                    className="nav-item"
                    eventKey="security"
                    title={<span>Merchants</span>}
                  >
                    <MerchantsList merchantsData={merchantsList} />
                  </Tab>
                  <Tab
                    className="nav-item"
                    eventKey="notifications"
                    title={<span>Categories</span>}
                  >
                    <CategoryList categoryData={categoryList} />
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
