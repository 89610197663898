import { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { RemoveAccess } from "./removeAccess";
import { EditEmployee } from "./editEmployee";
import { useSelector } from "react-redux";
import { ApiWrapper } from "../../../Api/apiWrapper";
import { INVALID_CODES, SUCCESS_CODES } from "../../../Api/constants";
import { Alert } from "react-bootstrap";

export const EmployeeInfo = ({ employeeDetail, getSelectedEmployee }) => {
  const profileImage = employeeDetail.profile_image
    ? employeeDetail.profile_image
    : "/images/default-profile-img.png";

  const user = useSelector((state) => state.user.user);
  const localData = JSON.parse(localStorage.getItem("data"));

  const [showRemoveAccessModal, setShowRemoveAccessModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [confirmModalLoader, setConfirmModalLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [showArchiveModal, setShowArchiveModal] = useState(false);

  const handleRemoveAccess = async () => {
    try {
      if (employeeDetail.status === "Key unassigned") {
        setSuccessMessage("");
        setErrorMessage("Keys not assigned to this user");
        return;
      }
      setConfirmModalLoader(true);
      const requestData = JSON.stringify({
        company_code: user.company_code,
        session_token: localData.session_token,
        locale: localStorage.getItem("i18nextLng"),
        employee_pk: employeeDetail.id,
      });
      const data = await ApiWrapper({
        path: "/employee/v1/removeemployeeaccess",
        method: "POST",
        data: requestData,
      });
      if (INVALID_CODES.includes(data.status_code)) {
        setErrorMessage(data.message || "Something went wrong!");
        setSuccessMessage("");
      } else if (SUCCESS_CODES.includes(data.status_code)) {
        await getSelectedEmployee();
      }
    } catch (error) {
      console.log({ error });
    } finally {
      setConfirmModalLoader(false);
      setShowRemoveAccessModal(false);
    }
  };

  const handleArchiveEmployee = async () => {
    try {
      if (employeeDetail.in_delete_process) {
        setSuccessMessage("");
        setErrorMessage(
          "The deletion process of this user is already in process"
        );
        return;
      }
      setConfirmModalLoader(true);
      const requestData = JSON.stringify({
        company_code: user.company_code,
        session_token: localData.session_token,
        locale: localStorage.getItem("i18nextLng"),
        employee_ids: [employeeDetail.id],
      });
      const data = await ApiWrapper({
        path: "/employee/v1/deleteemployee",
        method: "POST",
        data: requestData,
      });
      if (INVALID_CODES.includes(data.status_code)) {
        setSuccessMessage("");
        setErrorMessage(data.message || "Something went wrong!");
      } else if (SUCCESS_CODES.includes(data.status_code)) {
        // navigate("/employee");
        getSelectedEmployee();
        setErrorMessage("");
        setSuccessMessage(data.message || "User will be deleted in 16 days");
      }
    } catch (error) {
      console.log({ error });
    } finally {
      setConfirmModalLoader(false);
      setShowArchiveModal(false);
    }
  };

  return (
    <>
      {(errorMessage || successMessage) && (
        <Alert
          variant={successMessage ? "success" : "danger"}
          dismissible
          onClose={() => setErrorMessage("")}
          className="position-absolute m-0"
          style={{ top: "0", left: "0", right: "o", zIndex: "9999" }}
        >
          {errorMessage || successMessage}
        </Alert>
      )}
      <div className="col-lg-4 col-md-4 order-1 order-md-0">
        <div className="card mb-4">
          <div className="card-body">
            <div className="editProfile d-flex justify-content-end mb-3">
              <DropdownButton
                style={{ marginRight: "-9px" }}
                className="iconBtn"
                variant="text"
                align={"end"}
                title={
                  <>
                    <i className="ti ti-dots-vertical ti-sm cursor-pointer"></i>
                  </>
                }
              >
                <Dropdown.Item
                  className="d-flex align-items-center"
                  onClick={() => setShowEditModal(true)}
                >
                  <i className="ti ti-edit ti-sm cursor-pointer me-2"></i>{" "}
                  <span>Edit</span>
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => setShowRemoveAccessModal(true)}
                  className="d-flex align-items-center"
                >
                  <i className="ti ti-ban ti-sm cursor-pointer me-2"></i>{" "}
                  <span>Remove Access</span>
                </Dropdown.Item>
                <Dropdown.Item
                  className="d-flex align-items-center"
                  onClick={() => setShowArchiveModal(true)}
                >
                  <i className="ti ti-trash ti-sm cursor-pointer me-2"></i>{" "}
                  <span>Archive Employee</span>
                </Dropdown.Item>
              </DropdownButton>
              {showRemoveAccessModal && (
                <RemoveAccess
                  title={"Remove App Access"}
                  message={
                    "Are you sure you want to remove app access? After removing the access,you will no longer have the ability to activate it."
                  }
                  handleClose={() => setShowRemoveAccessModal(false)}
                  handleOkBtn={handleRemoveAccess}
                  loading={confirmModalLoader}
                />
              )}
              {showArchiveModal && (
                <RemoveAccess
                  title={"Archive User"}
                  message={"Are you sure you want to archive the user?"}
                  handleClose={() => setShowArchiveModal(false)}
                  handleOkBtn={handleArchiveEmployee}
                  loading={confirmModalLoader}
                />
              )}
            </div>
            <div className="user-avatar-section pb-2 border-bottom">
              <div className="d-flex align-items-center flex-column">
                <div className="userImg-holder position-relative mb-4">
                  <div className="overflow-hidden rounded userImg-holder">
                    <img
                      src={profileImage}
                      alt="User Profile image"
                      height="100"
                      width="100"
                      className="img-fluid userImg"
                    />
                  </div>
                  <img
                    src="/images/employee-badge.svg"
                    alt="User Profile image"
                    height="19"
                    width="26"
                    className="img-badge position-absolute"
                  />
                </div>
                <div className="user-info text-center">
                  <h4 className="mb-2">
                    {employeeDetail.first_name} {employeeDetail.last_name}
                  </h4>
                  <p>{employeeDetail.email}</p>
                  <span className="badge bg-label-success">
                    {employeeDetail.status}
                  </span>
                </div>
              </div>
              <div className="userList-info mt-4">
                <div className="row align-items-start justify-content-between">
                  <div className="col-md-6 col-12 pe-2 mb-3">
                    <div className="item d-flex align-items-center bg-label-secondary rounded py-3 px-2 w-100">
                      <span className="icon text-primary">
                        <i className="ti ti-mood-smile ti-lg"></i>
                      </span>
                      <div className="text ps-2">
                        <h6 className="mb-0">{`${
                          employeeDetail.total_smiles ?? 0
                        }K`}</h6>
                        <p className="text-muted mb-0">Smiles</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-12 ps-2 mb-3">
                    <div className="item d-flex align-items-center bg-label-secondary rounded py-3 px-2 w-100">
                      <span className="icon text-primary">
                        <i className="ti ti-currency-dollar ti-lg"></i>
                      </span>
                      <div className="text ps-2">
                        <h6 className="mb-0">
                          AED {employeeDetail.total_savings ?? 0}
                        </h6>
                        <p className="text-muted mb-0">Savings</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row align-items-start justify-content-between">
                  <div className="col-md-6 col-12 pe-2 mb-3">
                    <div className="item d-flex align-items-center bg-label-secondary rounded py-3 px-2 w-100">
                      <span className="icon text-primary">
                        <i className="ti ti-circle-check ti-lg"></i>
                      </span>
                      <div className="text ps-2">
                        <h6 className="mb-0">
                          {employeeDetail.total_redemptions ?? 0}
                        </h6>
                        <p className="text-muted mb-0">Redemptions</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-12 ps-2 mb-3">
                    <div className="item d-flex align-items-center bg-label-secondary rounded py-3 px-2 w-100">
                      <span className="icon text-primary">
                        <i className="ti ti-users ti-lg"></i>
                      </span>
                      <div className="text ps-2">
                        <h6 className="mb-0">{employeeDetail.user_group}</h6>
                        <p className="text-muted mb-0">Family</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="info-container pt-3">
              <ul className="list-unstyled">
                <li className="mb-2">
                  <span className="fw-medium me-1">Employee ID:</span>
                  <span>#{employeeDetail.employee_id}</span>
                </li>
                <li className="mb-2 pt-1">
                  <span className="fw-medium me-1">Tier:</span>
                  <span>{employeeDetail.tier_name}</span>
                </li>
                <li className="mb-2 pt-1">
                  <span className="fw-medium me-1">Phone Number:</span>
                  <span>{employeeDetail.mobile_phone}</span>
                </li>
                <li className="mb-2 pt-1">
                  <span className="fw-medium me-1">Role:</span>
                  <span>{employeeDetail.designation}</span>
                </li>
                <li className="mb-2 pt-1">
                  <span className="fw-medium me-1">Languages:</span>
                  <span>{employeeDetail.language_preference}</span>
                </li>
                <li className="mb-2 pt-1">
                  <span className="fw-medium me-1">Country:</span>
                  <span>{employeeDetail.nationality}</span>
                </li>
                <li className="pt-1">
                  <span className="fw-medium me-1">Country of residence:</span>
                  <span>{employeeDetail.country_of_residence}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {showEditModal && (
        <EditEmployee
          title={"Edit Info"}
          cancelBtnText={"Cancel"}
          oKBtnText={"Save"}
          onClose={() => setShowEditModal(false)}
          employeeDetail={employeeDetail}
          callback={getSelectedEmployee}
          endpoint={"edit_employee"}
        />
      )}
    </>
  );
};
