import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

export const SideBar = ({closeSider}) => {

  const modules = useSelector((state) => state.module.module);
  const [selectedModule, setSelectedModule] = useState("");

  useEffect(() => {
    const path = window.location.pathname;
    const module = modules.find((module) => module.url===path);
    if(module) {
      setSelectedModule(module.module_name);
    } else {
      if(path === "/profile") {
        setSelectedModule("Dashboard");
      }
      if(path.includes("employee/detail")) {
        setSelectedModule("Employees");
      }
    }
  },[]);

  return (
    <aside id="layout-menu" className="layout-menu menu-vertical menu bg-menu-theme">
      <div className="app-brand demo">
        <Link to="/dashboard" className="app-brand-link">
          <img src="/images/app-logo.png" alt="Rewards Dashboard" className="img-fluid"/>
        </Link>
        <span
          // href="javascript:void(0);"
          class="layout-menu-toggle menu-link text-large ms-auto"
          onClick = {()=>closeSider()}
        >
          <i class="ti menu-toggle-icon d-none d-xl-block ti-sm align-middle"></i>
          <i class="ti ti-x d-block d-xl-none ti-sm align-middle"></i>
        </span>
      </div>
      <div className="menu-inner-shadow"></div>
      <ul className="menu-inner py-1">
        {modules?.map(
          (module, index) =>
            module.is_visible && (
              <li
                key={index}
                className={`menu-item ${
                  selectedModule === module.module_name ? "active" : ""
                }`}
              >
                <Link to={module.url} className="menu-link">
                  <i className={`menu-icon tf-icons ti ${module.icon}`}></i>
                  {module.module_name}
                </Link>
              </li>
            )
        )}
      </ul>
    </aside>
  );
};
