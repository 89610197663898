import React from "react";

export const UserInfo = (props) => {
  const userData = props.userData;
  let phoneNumber = userData.mobile_no.replace(/\D/g, "");
  phoneNumber = `(${phoneNumber.substring(0, 3)}) ${phoneNumber.substring(
    3,
    6
  )}-${phoneNumber.substring(6, 10)}`;

  return (
    <>
      <div className="col-lg-4 col-md-4 order-1 order-md-0">
        <div className="card mb-4">
          <div className="card-body">
            <div className="editProfile d-flex justify-content-end mb-3">
              {/*<input type="file" className="ti ti-edit ti-sm cursor-pointer"/>*/}
              <i
                className="ti ti-edit ti-sm cursor-pointer"
                onClick={props.handleShow}
              ></i>
            </div>
            <div className="user-avatar-section pb-4 border-bottom">
              <div className="d-flex align-items-center flex-column">
                <div className="userProfileImg position-relative mb-4">
                  <img
                    src={userData.profile_img}
                    alt="User Profile image"
                    height="100"
                    width="100"
                    className="mb-4"
                  />
                </div>
                <div className="user-info text-center">
                  <h4 className="mb-2">
                    {userData.first_name} {userData.last_name}
                  </h4>
                  <span className="badge bg-label-secondary mt-1">
                    {userData.role_name}
                  </span>
                </div>
              </div>
            </div>
            <p className="mt-4 small text-uppercase text-muted">Details</p>
            <div className="info-container">
              <ul className="list-unstyled">
                <li className="mb-2">
                  <span className="fw-medium me-1">Username:</span>
                  <span>{userData.user_name}</span>
                </li>
                <li className="mb-2 pt-1">
                  <span className="fw-medium me-1">Email:</span>
                  <span>{userData.email}</span>
                </li>
                <li className="mb-2 pt-1">
                  <span className="fw-medium me-1">Role:</span>
                  <span>{userData.designation}</span>
                </li>
                <li className="mb-2 pt-1">
                  <span className="fw-medium me-1">ID:</span>
                  <span>{userData.employee_id}</span>
                </li>
                <li className="mb-2 pt-1">
                  <span className="fw-medium me-1">Contact:</span>
                  <span>{phoneNumber}</span>
                </li>
                <li className="mb-2 pt-1">
                  <span className="fw-medium me-1">Languages:</span>
                  <span>{userData.language}</span>
                </li>
                <li className="pt-1">
                  <span className="fw-medium me-1">Country:</span>
                  <span>{userData.country}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
