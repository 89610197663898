import Offcanvas from "react-bootstrap/Offcanvas";
import React, { useEffect, useState } from "react";
import { ResponseModal } from "../../Employee/responseModal";
import {
  INVALID_CODES,
  KEY_ADDED,
  REQUEST_FAILED,
  SUCCESS_CODES,
} from "../../../Api/constants";
import { useSelector } from "react-redux";
import { ApiWrapper } from "../../../Api/apiWrapper";

export const AddKeys = ({ toggleRequestKeyComponent, config }) => {
  const user = useSelector((state) => state.user.user);
  const localData = JSON.parse(localStorage.getItem("data"));

  const [loading, setLoading] = useState(false);
  const [requestKeyData, setRequestKeyData] = useState([]);
  const [showResponseModal, setShowResponseModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setRequestKeyData(
      (config.tiers || [])?.map((tier) => ({
        user_group: tier.tier_user_group,
        key_quantity: "",
        tier_name: tier.tier_title,
      }))
    );
  }, [config]);

  const onChangeInputData = (index, value) => {
    if (/^\+?\d*$/.test(value)) {
      const tempData = [...requestKeyData];
      tempData[index] = { ...tempData[index], key_quantity: value };
      setRequestKeyData(tempData);
    }
  };

  const addRequestKeyHandler = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      const requestData = JSON.stringify({
        company_code: user.company_code,
        session_token: localData.session_token,
        locale: localStorage.getItem("i18nextLng"),
        keys: requestKeyData,
      });
      const data = await ApiWrapper({
        path: "/employee/v1/purchasekeys",
        method: "POST",
        data: requestData,
      });

      if (INVALID_CODES.includes(data.status_code)) {
        setErrorMessage(data.message || "Something went wrong!");
      } else if (SUCCESS_CODES.includes(data.status_code)) {
        //
      }
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false);
      setShowResponseModal(true);
    }
  };

  return (
    <>
      <div className={`custom-backdrop`} />
      <Offcanvas
        show={true}
        onHide={toggleRequestKeyComponent}
        placement="end"
        backdrop={false}
      >
        {loading ? (
          <div className="pageLoader d-flex align-items-center justify-content-center position-absolute">
            <div className="spinner-border spinner-border-lg text-primary"></div>
          </div>
        ) : (
          ""
        )}
        {showResponseModal ? (
          <ResponseModal
            toggleMainComponent={toggleRequestKeyComponent}
            operation={errorMessage ? REQUEST_FAILED : KEY_ADDED}
            heading={errorMessage ? "Error" : "Request Sent"}
            message={
              errorMessage
                ? errorMessage
                : "You request for app key(s) has been successfully sent you the admin."
            }
          />
        ) : (
          <div className="d-flex flex-column h-100">
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Request Key Purchase</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div>
                <p>
                  Please select tier and enter the number of app key(s) you want
                  to purchase.
                </p>
              </div>
              <form onSubmit={addRequestKeyHandler}>
                <div>
                  {requestKeyData?.map((tier, index) => (
                    <div className="row align-items-center mb-4">
                      <div className="col-md-8 col-12">
                        <label
                          className="form-label"
                          style={{ fontSize: "15px" }}
                        >
                          {tier.tier_name}
                        </label>
                      </div>
                      <div className="col-md-4 col-12">
                        <input
                          required
                          type="text"
                          className="form-control"
                          id={index}
                          value={tier.key_quantity}
                          onChange={(event) =>
                            onChangeInputData(index, event.target.value)
                          }
                        />
                      </div>
                    </div>
                  ))}
                </div>
                <div className="bottom-btnWrap d-flex justify-content-between gap-3 position-absolute bottom-0 start-0 end-0 pb-4 ps-4 pe-4">
                  <button className="btn btn-primary d-grid w-100">
                    Send request to the loyalty manager
                  </button>
                </div>
              </form>
            </Offcanvas.Body>
          </div>
        )}
      </Offcanvas>
    </>
  );
};
